@import 'variables.scss';
@import 'functions.scss';
@import 'mixins.scss';
@import 'fonts.scss';
@import 'placeholders.scss';

.a4-exposition {
    $root: &;

    &-container {
        @include container($sm: 728px, $md: 984px, $xl: 1440px);
        @include paddingX(18px);
    }

    &-content {
        @include marginY(80px);
        display: flex;
        align-items: center;
        flex-direction: column;

        &.-shell {
            border-radius: 30px;
            background: var(--primary-light);
        }

        @include sm {
            padding-left: 62px;
            @include marginY(100px, 112px);

            flex-direction: row;
            justify-content: space-between;

            &.-shell {
                border-radius: 40px;
            }
        }
        @include md {
            padding-left: 98px;
            @include marginY(134px);
        }
        @include xl {
            padding-left: 200px;
            @include marginY(173px, 160px);
        }
    }

    &-illustration {
        text-align: center;

        img {
            display: block;
            width: 283px;
            height: 237px;

            @include md {
                width: 389px;
                height: 326px;
            }
            @include xl {
                width: 609px;
                height: 511px;
            }
        }
    }

    &-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        margin-bottom: 40px;
        z-index: 1;

        @include sm {
            display: block;
            text-align: left;

            max-width: 284px;
            margin-bottom: 0;
        }
        @include md {
            max-width: 373px;
        }
        @include xl {
            max-width: 460px;
        }
    }

    &-title {
        @extend .text-lg;
        font-weight: 800;
        color: var(--secondary);
    }

    &.-highlighted {
        #{$root} {
            &-content {
                @extend .-shell;
                @include paddingY(30px);
                @include sm {
                    padding-left: 0;
                }
            }

            &-info {
                text-align: center;
                @include paddingX(15px);
                margin-bottom: 0;
                @include sm {
                    margin-bottom: 45px;
                }
            }

            &-title {
                @extend .text-xl;
                font-weight: bold;
            }
            &-control {
                margin-top: 20px;
                @include sm {
                    margin-top: 40px;
                }
            }
            &-illustration {
                position: relative;

                &-background {
                    position: absolute !important;
                    bottom: 0;
                    right: 0;
                    height: 75%;
                }

                img {
                    position: relative;
                    display: block;
                    width: 262px;
                    &:last-of-type {
                        height: 237px;
                    }
                }
            }
        }

        @include sm {
            #{$root} {
                &-info {
                    max-width: 339px;
                    @include paddingX(0px);
                }

                &-content {
                    flex-direction: column;
                    @include paddingY(80px, 50px);
                    margin-bottom: 100px;
                }

                &-illustration img {
                    width: 411px;
                    &:last-of-type {
                        height: 371px;
                    }
                }
            }
        }

        @include md {
            #{$root} {
                &-content {
                    flex-direction: row;
                    @include paddingX(98px, 20px);
                }

                &-info {
                    max-width: 391px;
                    text-align: left;
                }
            }
        }

        @include xl {
            #{$root} {
                &-content {
                    @include paddingX(200px, 58px);
                }

                &-info {
                    max-width: 449px;
                }

                &-illustration img {
                    width: 609px;
                    &:last-of-type {
                        height: 550px;
                    }
                }
            }
        }
    }

    &-control {
        margin-top: 40px;

        @include sm {
            margin-top: 50px;
        }
        @include xl {
            margin-top: 60px;
        }
    }

    &-description {
        @extend %description;
    }
}
