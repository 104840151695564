.select2 {
    $root: &;
  
    width: 100% !important;
    height: 100%;
  
    &-container {
  
      &--open {
        #{$root}-selection #{$root}-selection--single {
          background: black;
        }
      }
  
      #{$root}-selection {
        height: 100%;
        @include paddingY(15px);
        @include paddingX(20px);
  
        border-radius: 10px;
  
        &__rendered {
          @include set-font(16px, 25px);
        }
  
        @include md {
          @include paddingY(17px);
        }
      }
    }
  
    &-dropdown {
      top: 4px;
  
      overflow: hidden;
      border: 1px solid #E2E2E2;
      border-radius: 10px !important;
    }
  
    @include sm {
      min-width: 166px;
      max-width: 210px;
    }
}
  
.select2-container--open .select2-dropdown--below {
  border-top: 1px solid #E2E2E2;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single {
  border-radius: 10px;
}

.select2-results__options {
  @include paddingY(20px);
  overflow-x: hidden;
}

.select2-container .select2-selection__arrow {
  top: 50% !important;
  right: 10px !important;
  transform: translateY(-50%);

  display: flex;
  align-items: center;
  justify-content: center;


  b {
    width: 15px !important;
    height: 9px !important;

    left: 0 !important;

    margin-left: 0px !important;
    margin-top: 0px !important;

    position: relative !important;
    top: 0 !important;
    display: block;

    border: none !important;
    background: url("../assets/chevron.svg") 100% 100% no-repeat;
    background-position: center;

    transition: transform 0.2s;
  }
}

.select2-container--open .select2-selection__arrow {

  b {
    transform:rotate(180deg);
  }
}

.select2-results__option {
  @include paddingY(11px);
  padding-left: 30px;

  &[aria-selected="true"] {
    background: var(--primary-light) !important;
    color: var(--secondary) !important;
  }

  &--highlighted {
    background: transparent !important;
    color: var(--primary) !important;
  }
}

.select2-container--default .select2-selection--single {
  border: 1px solid #E2E2E2;
}
  
.a4-select {
  height: 57px;

  @include md {
    height: 61px;
  }
}