@import 'variables.scss';
@import 'functions.scss';
@import 'mixins.scss';
@import 'fonts.scss';
@import 'placeholders.scss';

.a4-spacer {
    position: relative;
    margin: auto;

    &-container {
        width: 100%;
        overflow: hidden;
    }

    &-content {
        margin: 29px 0 25px 0;
        display: flex;
        justify-content: center;
        @include paddingX(15px);

        &-wrapper {
            position: relative;
        }
        &-background {
            position: absolute !important;
            bottom: 0;
            left: 0;
            right: 0;

            width: 95% !important;
            margin: 0 auto;
        }

        img {
            position: relative;
            width: auto;
            height: 135px;
            @include sm {
                height: auto;
                width: 100%;
                max-width: 693px;
            }
            @include md {
                max-width: 829px;
            }
            @include xl {
                max-width: 1105px;
            }
        }

        @include sm {
            margin: 67px 0 60px 0;

            @include paddingX(0);
        }

        @include md {
            margin: 36px 0 28px 0;

        }

        @include xl {
            margin: 49px 0 37px 0;

        }
    }

    &-filler {
        @extend %a4-filler;
        &.primary_light {
            background: var(--primary-light);
        }
        &.light_gray {
            background: #F8F9FA;
        }
    }
}
