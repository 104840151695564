@import 'variables.scss';
@import 'functions.scss';
@import 'mixins.scss';
@import 'fonts.scss';
@import 'placeholders.scss';

@mixin items() {
    &-items {
      margin-bottom: 40px;
  
      @content;
  
      @include sm {
        margin-bottom: 0;
      }
    }
}
  
.a4-list {
    $root: &;
  
    &-container {
      @include container(
              $sm: 728px,
              $md: 984px,
              $xl: 1440px
      );
    }
  
    &-content {
      @include marginY(80px);
  
      @include sm {
        @include marginY(112px, 90px);
      }
      @include md {
        @include marginY(134px, 100px);
      }
      @include xl {
        @include marginY(160px, 200px);
      }
    }
  
    &-body {
      @include container(
              $sm: 604px,
              $md: 794px,
              $xl: 1040px
      );
      @include paddingX(14px);
  
      @include sm {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
      }
  
      @include xl {
        width: auto !important;
        @include paddingX(200px, 123px);
      }
    }
  
    &-info {
      margin-bottom: 40px;
      &-control {
        display: none;
        @include sm {
          display: block;
          margin-top: 50px;
        }
        @include xl {
          margin-top: 60px;
        }
      }
  
      @include sm {
        min-width: 283px;
        width: 283px;
        margin-bottom: 0;
  
        margin-right: 36px;
      }
      @include md {
        min-width: 380px;
        width: 380px;
      }
      @include xl {
        min-width: 460px;
        width: 460px;
  
        margin-right: 100px;
      }
    }
  
    &-title {
      @extend .text-lg;
      font-weight: 800;
      color: var(--secondary);
    }
  
    &-description {
      @extend %description;
    }
  
    &-items {
      flex: 1;
    }
  
    &.-courses {
      #{$root} {
        &-items {
          margin-bottom: 40px;
  
          &-item {
  
            &-image {
              margin-bottom: 30px;
  
              img {
                @include size(92px);
  
                @include md {
                  @include size(133px);
                  margin-right: 37px;
                }
  
                @include xl {
                  @include size(210px);
                }
              }
            }
  
            &-title {
              margin-bottom: 6px;
  
              @extend .text-md;
              font-weight: bold;
            }
  
            &-description {
              @extend %description;
            }
  
            &:not(:first-child) {
              margin-top: 60px;
            }

            &-details {
              display: flex;
              flex-direction: row;
              margin-top: 10px;

              &-label {
                font-size: 14px;
                line-height: 24px !important;
                letter-spacing: normal;
                font-weight: 500;
                color: var(--primary);
                white-space: nowrap;

                @include md {
                  font-size: 16px;
                  line-height: 26px !important;
                  letter-spacing: normal;
                }

                @include xl {
                  font-size: 18px;
                  line-height: 28px !important;
                  letter-spacing: normal;
                }
              }

              span {
                font-size: 16px;
                line-height: 24px !important;
                letter-spacing: normal;
                font-weight: 600;
                color: var(--secondary);
                padding-left: 10px;
                white-space: nowrap;

                @include md {
                  font-size: 18px;
                  line-height: 26px !important;
                  letter-spacing: normal;
                }

                @include xl {
                  font-size: 20px;
                  line-height: 28px !important;
                  letter-spacing: normal;
                }
              }

              @include xl {
                margin-top: 20px;
              }
            }
  
            @include sm {
              margin: unset;
  
              &:not(:first-child) {
                margin-top: 70px;
              }
            }
            @include md {
              a {
                display: flex;
              }
            }
            @include xl {
              &:not(:first-child) {
                margin-top: 179px;
              }
            }
          }
  
          @include sm {
            margin-bottom: 0;
          }
        }
      }
    }
  
    &.-whyUs {
      #{$root} {
        &-items {
          &-item {
            &-image {
              margin-bottom: 20px;
  
              img {
                width: 49px;
  
                @include xl {
                  width: 87px;
                }
              }
  
              @include md {
                margin-bottom: 0;
                margin-right: 36px;
              }
            }
  
            &-title {
              @extend .text-md;
              font-weight: bold;
            }
  
            &-description {
              @extend %description;
              margin-top: 5px;
            }
  
            &:not(:first-child) {
              margin-top: 60px;
            }
  
            @include md {
              display: flex;
            }
          }
        }
      }
    }
  
    &.-howItWorks {
      #{$root} {
        &-items {
          counter-reset: item;
  
          &-item {
  
            &-number:before {
              display: block;
              margin-bottom: 30px;
  
              min-width: 46px;
              margin-right: 36px;
  
              counter-increment: item;
              content: counter(item, decimal-leading-zero) ".";
  
              @include set-font(32px, 34px);
              color: var(--primary);
              font-weight: normal;
            }
  
            &-title {
              @extend .text-md;
              font-weight: bold;
            }
  
            &-description {
              @extend %description;
              margin-top: 10px;
  
              @include md {
                margin-top: 5px;
              }
            }
  
            &:not(:first-child) {
              margin-top: 60px;
            }
  
            @include md {
              display: flex;
  
              &-number:before {
                margin-bottom: 0px;
                margin-top: 5px;
              }
            }
            @include xl {
              &-number:before {
                @include set-font(42px, 34px);
                min-width: 60px;
                margin-top: 10px;
              }
            }
          }
        }
      }
    }
  
    &.-highlighted {
      &.-courses {
        #{$root}-items-item {
          &-description {
            color: rgba(var(--secondaryR), var(--secondaryG), var(--secondaryB), 0.5);
          }
        }
      }
  
      #{$root} {
        &-content {
          @include paddingY(60px);
          border-radius: 30px;
          background: var(--primary-light);
  
          @include sm {
            @include paddingY(90px);
            border-radius: 40px;
          }
  
          @include md {
            @include paddingY(134px);
          }
  
          @include xl {
            @include paddingY(147px, 183px);
          }
        }
      }
    }
  
    &-control {
      margin-top: 40px;
      display: flex;
      justify-content: center;
  
      @include sm {
        display: none;
      }
    }
  }