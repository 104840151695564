@import 'variables.scss';
@import 'mixins.scss';

.a4-pagination {
    display: flex;
    justify-content: center;
    padding: 50px 0px;
    
    @include sm {
        padding: 50px 50px;
    }
}