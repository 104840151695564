@import 'variables.scss';
@import 'functions.scss';
@import 'mixins.scss';
@import 'fonts.scss';
@import 'placeholders.scss';

.a4-pricing {
    $root: &;

    &-header {
        background: var(--primary-light);
        @include paddingX(20px);
        @include sm {
            @include paddingX(0);
        }
    }

    &-page-title {
        position: relative;
        @extend %page-title;

        @include sm {
            max-width: 631px;
            margin: auto;
        }

        @include md {
            max-width: 814px;
            margin: auto;
        }

        @include xl {
            max-width: 1194px;
            margin: auto;
        }
    }
    
    &-header {
        background: var(--primary-light);
        @include sm {
            border-radius: 0 0 40px 40px;
        }

        @include lg {
            border-radius: 0 0 100px 100px;
        }
    }

    &-filter-panel {
        background: var(--primary-light);
        border-radius: 0 0 30px 30px;

        &-container {
            @include paddingX(20px);

            @include sm {
                max-width: 604px;
                margin: auto;
            }

            @include md {
                max-width: 814px;
                margin: auto;
            }

            @include xl {
                max-width: 1194px;
                margin: auto;
            }
        }

        &-content {
            display: flex;
            width: 100%;
            @include paddingY(70px, 40px);
        }

        &-section-1 {
            width: 100%;

            #filter-menu-btn {
                background: #fff;

                width: 100%;

                @include set-font(14px, 25px);
                @include paddingY(15px);
                @include paddingX(35px);
                border: 1px solid #e2e2e2;
                border-radius: 10px;

                i {
                    display: inline-flex;
                    margin-right: 10px;
                }

                &-items-count {
                    opacity: 0.3;
                }

                @include md {
                    @include paddingY(17px);
                }

                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &-section-2 {
            display: none;

            #exp-date-filter,
            #category-filter {
                display: none;
            }

            @include sm {
                display: flex;
                flex: 1;
            }

            @include md {
                #exp-date-filter,
                #category-filter {
                    display: block;
                }
            }
        }

        &-section-3 {
            display: none;

            @include md {
                display: flex;
            }
        }
    }
    
    @include sm {
        &-container {
            @include paddingX(0);
        }

        &-filter-panel {
            border-radius: 0 0 40px 40px;

            &-content {
                display: flex;
                @include paddingY(97px, 40px);

                #filter-menu-btn {
                    flex: 0;
                    min-width: 220px;
                    margin: 0;
                }
            }

            &-section-2 {
                margin-right: 25px;

                .filter-item + .filter-item {
                    margin-left: 10px;
                }

                div.a4-input.-search {
                    display: none;
                }
            }

            &-section-3 {
                #{$root}-filter-panel-section-title {
                    display: flex;
                    align-items: center;
                    margin-right: 15px;

                    h4 {
                        @include set-font(14px, 25px);
                        font-weight: bold;
                        color: var(--secondary);

                        &:after {
                            content: ":";
                        }
                    }
                }
            }

            &-menu {
                &-top {
                    margin-bottom: 30px;
                }

                &-title {
                    h4 {
                        @include set-font(20px, 30px);
                    }
                }

                &-cancel {
                    right: 30px;
                }

                &-search {
                    display: none;
                }

                &-items {
                    &-item {
                        &.-active {
                            &:after {
                                right: 57px;
                            }
                        }
                    }
                }
            }
        }
    }

    @include md {
        &-filter-panel {
            &-content {
                @include paddingY(80px, 40px);
            }

            &-section-2 {
                margin-right: 25px;

                .filter-item + .filter-item {
                    margin-left: 10px;
                }

                div.a4-input.-search {
                    height: 61px;
                    display: block;
                    flex: 1;
                }

                .a4-select {
                    width: 210px;
                }
            }

            &-section-3 {
                .a4-select {
                    width: 220px;
                }
            }
        }
    }

    @include lg {
        &-filter-panel {
            border-radius: 0 0 100px 100px;
        }
    }

    @include xl {
        &-title {
            padding-top: 200px;
        }

        &-filter-panel {
            &-section-2 {
                #category-filter {
                    flex: 0 1 210px;
                }

                #exp-date-filter {
                    flex: 0 1 210px;
                }

                .filter-item + .filter-item {
                    margin-left: 20px;
                }

                .a4-select {
                    width: 210px;
                }

                margin-right: 137px;
            }

            &-section-3 {
                .a4-select {
                    width: 210px;
                }
            }
        }
    }

    &-memberships {
        @include paddingY(40px);
        @include paddingX(20px);

        @include sm {
            @include paddingY(60px);
            @include paddingX(0px);
        }
    }

    .a4-mobile-filter-items-item a {
        display: flex;
        flex: 1;
    }

    &-list-no-results {
        display: flex;
        justify-content: center;
        padding: 35px 0px 20px 0px;

        h3 {
            @extend .text-md;
            font-weight: bold;
            text-align: center;

        }
    }
}
