@import 'variables.scss';
@import 'functions.scss';
@import 'mixins.scss';
@import 'fonts.scss';
@import 'placeholders.scss';

.a4-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;

    background-color: rgba(24, 40, 72, 0.2);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    opacity: 1;
    visibility: visible;

    transition: opacity 0.2s;

    &:not(.-opened) {
        touch-action: none;
        pointer-events: none;
        opacity: 0;
        visibility: hidden;
    }

    .-cancel {
        position: absolute;
        right: 40px;
        top: 40px;
        width: 15px;
        height: 15px;
        color: var(--primary);
        cursor: pointer;
    }

    .-back {
        position: absolute;
        left: 40px;
        top: 40px;
        width: 17px;
        height: 17px;
        color: var(--primary);
        cursor: pointer;
    }

    &-content {
        background-color: #ffffff;
        width: 100%;
        height: 100%;

        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include sm {
            margin: 0 auto;
            width: 701px;
            height: auto;
            min-height: 343px;
            padding: 90px 152px;
            border-radius: 30px;
        }
        @include md {
            padding: 90px 132px;
        }
        @include xl {
            padding: 90px 117px;
        }
    }
}