@import 'variables.scss';
@import 'functions.scss';
@import 'mixins.scss';
@import 'fonts.scss';
@import 'placeholders.scss';

.a4-ce {
    &-container {
        @include container($sm: 605px, $md: 814px, $xl: 1192px);
    }

    &-content {
        @include paddingY(60px);

        @include sm {
            @include paddingY(80px);
        }

        @include md {
            display: flex;
            @include paddingY(110px, 140px);
        }

        @include xl {
            @include paddingY(110px);
        }
    }

    &-page-title {
        @extend %page-title;
    }

    &-states-panel {
        &-control {
            &-label {
                @extend .text-sm;
                color: var(--secondary);
                font-weight: bold;

                margin-bottom: 20px;

                &:after {
                    content: ":";
                }

                @include md {
                    margin-bottom: 30px;
                }
            }

            .a4-input {
                display: none;
            }

            @include sm {
                .a4-select {
                    display: none;
                }

                .a4-input {
                    display: block;
                }
            }
        }

        &-list {
            position: relative;
            display: none;
            margin-top: 40px;
            @include paddingY(60px);

            &-fill {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                max-width: 100vw;
                z-index: -1;

                margin: 0 calc(-50vw + 50% + 20px);

                @include shadow();
                &::after {
                    content: "";
                    @extend %a4-filler;
                    background: #ffffff;
                    border-radius: 30px;
                }

                @include md {
                    max-width: 100%;
                    margin: 0 auto;
                }
            }

            ul {
                max-height: 722px;
                display: flex;
                flex-flow: column wrap;
                align-content: flex-start;

                margin-top: -22px;

                li {
                    @extend .text-sm;

                    margin-top: 22px;
                    width: 33%;

                    &:hover {
                        color: var(--primary);
                    }

                    &.-active {
                        color: var(--primary);
                        font-weight: bold;
                    }

                    @include md {
                        width: unset;
                    }
                }

                @include md {
                    flex-flow: column nowrap;
                    max-height: unset;
                }
            }

            &-no-results {
                text-align: center;
                padding-top: 22px;
            }

            @include sm {
                display: block;
            }

            @include md {
                @include paddingX(60px);
            }
        }

        @include md {
            display: flex;
            flex-direction: column;
            min-width: 282px;
            width: 282px;

            margin-right: 58px;
        }

        @include xl {
            display: flex;
            flex-direction: column;
            min-width: 333px;
            width: 333px;

            margin-right: 160px;
        }
    }

    &-info {
        margin-top: 40px;

        @include sm {
            margin-top: 60px;
        }

        @include md {
            margin-top: 0px;
        }
    }

    &-article {
        &-title {
            h2 {
                @extend .text-lg;
                font-weight: 800;
            }
        }

        &-content {
            margin-top: 30px;

            p {
                @extend .text-sm;
                color: rgba(var(--secondaryR), var(--secondaryG), var(--secondaryB), 0.5);
            }
            

            p + p {
                margin-top: 2em;
            }

            ul {
                margin-left: 45px;
                list-style: circle;
            }

            li {
                @extend .text-sm;
                color: rgba(var(--secondaryR), var(--secondaryG), var(--secondaryB), 0.5);
            }
        }
    }

    &-contact-card {
        margin-top: 40px;
        @include paddingY(40px);
        @include paddingX(30px);

        background: var(--primary-light);
        border-radius: 30px;

        &-title {
            margin-bottom: 30px;

            h4 {
                @extend .text-sm;
                font-weight: bold;
            }
        }

        &-item {
            display: flex;
            &-icon {
                display: none;
                width: 80px;
                height: 80px;
                background: rgba(36, 216, 197, 0.15);
                border-radius: 40px;
                margin-right: 20px;

                .-world {
                    width: 28px;
                    height: 28px;
                }
                .-phone {
                    width: 23px;
                    height: 37px;
                }
                .-letter {
                    width: 31px;
                    height: 26px;
                }
                .-social {
                    width: 33.1px;
                    height: 30.5px;
                }
                @include md {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-shrink: 0;
                }
            }

            & + & {
                margin-top: 53px;
                @include md {
                    margin-top: 30px;
                }
            }

            &-label {
                @extend .text-xs;
                color: rgba(var(--secondaryR), var(--secondaryG), var(--secondaryB), 0.3);
            }

            &-text {
                @extend .text-xs;
                font-weight: 600;
                word-break: break-word;
            }
        }
    }
}
