@import 'variables.scss';
@import 'functions.scss';
@import 'mixins.scss';
@import 'fonts.scss';
@import 'placeholders.scss';
@import 'button.scss';

.a4-home {
    &-content {
        margin-top: 85px;

        @include sm {
            margin-top: 110px;
        }

        @include md {
            margin-top: 137px;
        }

        @include xl {
            margin-top: 150px;
        }
    }
}

.a4-main-container {
    @include container($sm: 604px, $md: 794px, $xl: 1040px);
}

.a4-courses {
    &-container {
        @include container($sm: 728px, $md: 984px, $xl: 1440px);
    }

    &-content {
        @include paddingY(60px);
        border-radius: 30px;
        background: var(--primary-light);

        @include sm {
            @include paddingY(90px);
            border-radius: 40px;
        }
        @include md {
            @include paddingY(134px);
        }
        @include xl {
            @include paddingY(147px, 183px);
        }
    }

    &-body {
        @include container($sm: 604px, $md: 794px, $xl: 1040px);
        @include paddingX(14px);

        @include sm {
            display: flex;
            justify-content: space-between;
        }

        @include xl {
            width: auto !important;
            @include paddingX(200px, 123px);
        }
    }

    &-info {
        margin-bottom: 60px;

        &-card {
            &-title {
                @extend .text-lg;
                font-weight: 800;
                color: var(--secondary);
            }

            &-description {
                @extend %description;
            }

            &-control {
                display: none;
                @include sm {
                    display: block;
                    margin-top: 50px;
                }
                @include xl {
                    margin-top: 60px;
                }
            }
        }

        @include sm {
            margin-bottom: 0px;
        }
    }

    &-list {
        margin-bottom: 40px;

        &-item {
            &-image {
                margin-bottom: 30px;

                img {
                    @include size(92px);

                    @include sm {
                        @include size(133px);
                    }

                    @include md {
                        margin-right: 37px;
                    }

                    @include xl {
                        @include size(210px);
                    }
                }
            }

            &-title {
                margin-bottom: 6px;

                h3 {
                    @extend .text-md;
                    font-weight: bold;
                }
            }

            &-description {
                @extend %description;
            }

            &:not(:first-child) {
                margin-top: 60px;
            }

            @include sm {
                max-width: 284px;
                margin: unset;

                &:not(:first-child) {
                    margin-top: 70px;
                }
            }
            @include md {
                display: flex;
                max-width: 390px;
            }
            @include xl {
                max-width: 579px;

                &:not(:first-child) {
                    margin-top: 179px;
                }
            }
        }

        @include sm {
            margin-bottom: 0;
        }
    }

    &-control {
        margin-top: 40px;
        display: flex;
        justify-content: center;

        @include sm {
            display: none;
        }
    }
}

.a4-illustration-spacer-1 {
    position: relative;

    &-container {
        @include paddingX(30px);

        @include sm {
            @include paddingX(67px);
        }

        @include md {
            @include paddingX(133px);
        }

        @include lg {
            @include paddingX(220px);
        }

        @include xl {
            @include paddingX(295px);
        }
    }

    &-content {
        position: relative;

        display: flex;
        justify-content: center;

        @include paddingY(25px);

        @include sm {
            @include paddingY(67px, 60px);
        }

        @include md {
            @include paddingY(36px, 28px);
        }

        @include xl {
            @include paddingY(49px, 37px);
        }
    }

    &-filler {
        @extend %a4-filler;
        background: var(--primary-light);
    }

    img.illustration-3 {
        width: 100%;

        @include xl {
            width: 1105px;
            height: 513px;
        }
    }
}

.a4-how-works {
    &-container {
        @include container($sm: 604px, $md: 814px, $xl: 1040px);
    }

    &-content {
        @include paddingY(60px, 80px);

        @include sm {
            display: flex;
            justify-content: space-between;

            @include paddingY(100px, 120px);
        }

        @include md {
            @include paddingY(120px, 160px);
        }

        @include xl {
            @include paddingY(180px, 240px);
        }
    }

    &-info {
        margin-bottom: 40px;

        @include sm {
            margin-bottom: 0;
        }
    }

    &-title {
        h2 {
            @extend .text-lg;
            font-weight: 800;
        }
    }

    &-description {
        @extend %description;
    }

    &-list {
        counter-reset: item;

        &-item {
            &-number:before {
                display: block;
                margin-bottom: 30px;

                counter-increment: item;
                content: counter(item, decimal-leading-zero) ".";

                @include set-font(32px, 34px);
                color: rgba(var(--secondaryR), var(--secondaryG), var(--secondaryB), 0.2);
                font-weight: normal;
            }

            &-title {
                h3 {
                    @extend .text-md;
                    font-weight: bold;
                }
            }

            &-description {
                @extend %description;
                margin-top: 5px;

                @include sm {
                    width: 284px;
                }
                @include md {
                    width: 307px;
                }
                @include xl {
                    width: 380px;
                }
            }

            &:not(:first-child) {
                margin-top: 60px;
            }

            @include md {
                display: flex;
                justify-content: space-between;

                &-number:before {
                    margin-bottom: 0px;
                    margin-right: 43px;
                    margin-top: 5px;
                }
            }
            @include xl {
                &-number:before {
                    font-size: 42px;
                    line-height: 34px;
                    margin-right: 49px;
                    margin-top: 10px;
                }
            }
        }
    }
}

.a4-account-form {
    &-container {
        @include container($sm: 604px, $md: 814px, $lg: 984px, $xl: 1154px);
    }

    &-content {
        padding-bottom: 80px;

        @include sm {
            display: flex;
            justify-content: space-between;
            padding-bottom: 100px;
        }

        @include md {
            padding-bottom: 169px;
        }

        @include lg {
            padding-bottom: 160px;
        }

        @include xl {
            padding-bottom: 221px;
        }
    }

    &-group {
        max-width: 284px;
    }

    &-info {
        margin-bottom: 40px;

        @include sm {
            margin-bottom: 0;
        }
        @include lg {
            margin-left: 86px;
        }
        @include xl {
            margin-left: 57px;
        }
    }

    &-title {
        h2 {
            @extend .text-lg;
            font-weight: 800;
        }
    }

    &-description {
        @extend %description;

        @include sm {
            width: 284px;
        }
        @include md {
            width: 379px;
        }
        @include xl {
            width: 460px;
        }
    }

    &-group {
        &:not(:first-child) {
            margin-top: 30px;
        }
        @include sm {
            max-width: 100%;
        }
    }

    &-control {
        margin-top: 40px;
        display: flex;
        align-items: center;

        button[type="submit"] {
            margin-right: 13px;
            font-size: 14px !important;
        }

        a.a4-already-have-acc {
            @include set-font(14px, 21px);
            font-weight: bold;
            color: var(--secondary);
        }

        @include sm {
            button[type="submit"] {
                margin-right: 16px;
            }
        }
        @include md {
            flex-direction: column;
            align-items: start;

            margin-top: 30px;
            button[type="submit"] {
                margin-right: 0;
                margin-bottom: 20px;
                font-size: 18px !important;
            }
            a.a4-already-have-acc {
                @include set-font(16px, 26px);
            }
        }
        @include lg {
            flex-direction: row;
            align-items: center;

            button[type="submit"] {
                margin-right: 6px;
                margin-bottom: 0px;
            }
        }
        @include xl {
            button[type="submit"] {
                @include set-font(18px, 28px);
                margin-right: 24px;
                margin-bottom: 0px;
            }

            a.a4-already-have-acc {
                @include set-font(18px, 28px);
            }
        }
    }

    &-terms {
        margin-top: 40px;

        @include set-font(13px, 24px);
        color: rgba(24, 40, 72, 0.4);

        a {
            color: var(--secondary);
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }

        @include md {
            @include set-font(14px, 24px);
            margin-top: 5px;
        }
        @include lg {
            margin-top: 60px;
        }
        @include xl {
            @include set-font(16px, 28px);
        }
    }

    @include sm {
        &-form {
            width: 284px;
        }
    }
    @include md {
        &-form {
            width: 304px;
        }
    }
    @include lg {
        &-form {
            width: 389px;
        }
    }
    @include xl {
        &-form {
            width: 437px;
        }
    }
}

.a4-illustration-spacer-2 {
    position: relative;
    margin: auto;

    &-container {
        width: 100%;
        overflow: hidden;
    }

    &-content {
        padding-top: 44px;
        display: flex;
        justify-content: center;

        @include sm {
            padding-top: 106px;
        }

        @include md {
            padding-top: 56px;
        }

        @include xl {
            padding-top: 73px;
        }
    }

    &-filler {
        @extend %a4-filler;
        background: var(--primary-light);
    }

    img {
        width: 100%;
        @include sm {
            max-width: 768px;
        }
        @include md {
            max-width: 878px;
        }
        @include md {
            max-width: 1105px;
        }
    }
}

.a4-why-use {
    &-container {
        @include container($sm: 644px, $md: 814px, $xl: 1040px);
    }

    &-content {
        @include paddingY(46px, 80px);

        @include sm {
            display: flex;
            justify-content: space-between;

            @include paddingY(80px, 100px);
        }

        @include md {
            @include paddingY(120px, 200px);
        }

        @include xl {
            @include paddingY(150px, 280px);
        }
    }

    &-info {
        margin-bottom: 40px;

        @include sm {
            padding-left: 20px;
        }
        @include md {
            padding-left: 0px;
        }
    }

    &-title {
        h2 {
            @extend .text-lg;
            font-weight: 800;
        }
    }

    &-description {
        @extend %description;

        @include sm {
            width: 284px;
        }
        @include md {
            width: 379px;
        }
        @include xl {
            width: 460px;
        }
    }

    &-list {
        &-item {
            &-image {
                margin-bottom: 20px;

                img {
                    width: 49px;

                    @include xl {
                        width: 100%;
                    }
                }

                @include md {
                    margin-bottom: 0;
                    margin-right: 36px;
                }
            }

            &-title {
                h3 {
                    @extend .text-md;
                    font-weight: bold;
                }
            }

            &-description {
                @extend %description;
                margin-top: 5px;

                @include sm {
                    width: 304px;
                }
                @include md {
                    width: 307px;
                }
                @include xl {
                    width: 380px;
                }
            }

            &:not(:first-child) {
                margin-top: 60px;
            }

            @include md {
                display: flex;
            }
        }
    }
}

.a4-find-plan {
    &-container {
        @include container($sm: 728px, $md: 984px, $xl: 1440px);
    }

    &-content {
        padding-bottom: 46px;

        @include sm {
            padding-bottom: 113px;
        }

        @include md {
            padding-bottom: 144px;
        }

        @include xl {
            padding-bottom: 164px;
        }
    }

    &-title {
        h1 {
            @extend .text-xl;
            font-weight: bold;
            text-align: center;
        }

        padding-bottom: 60px;

        @include sm {
            padding-bottom: 80px;
        }

        @include md {
            padding-bottom: 120px;
        }

        @include xl {
            padding-bottom: 160px;
        }
    }
}

.a4-main-client-reviews {
    &-container {
        @extend .a4-main-container;
    }

    &-content {
        margin-bottom: 80px;
    }
}

.a4-modal {
    .a4-find-plan-modal {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0 18px;

        &-title {
            text-align: center;
            @include set-font(24px, 32px, -0.02em);
            font-weight: 800;
            color: var(--secondary);
            margin-bottom: 20px;
        }

        &-btn-group {
            display: flex;
            flex-direction: column;

            @include sm {
                flex-direction: row;
            }
        }

        .a4-btn {
            text-align: center;
            margin-top: 30px;
            font-weight: bold;
            display: flex;
            justify-content: center;
            align-items: center;
            @include set-font(14px, 21px);

            &:not(.-primary) {
                color: var(--primary);
            }

            &.-inverted {
                @include paddingY(10px);
                @include paddingX(30px);

                border: 1px solid var(--primary);
                border-radius: 30px;
                color: var(--primary);

                @include fill(#fff);
                @include sm {
                    @include paddingY(14px);
                }

                // &:hover {
                //     text-decoration: underline;
                // }
            }

            @include sm {
                margin-right: 20px;
                height: 60px;
                &:last-child {
                    margin-right: 0;
                }
            }
            @include md {
                @include set-font(16px, 21px, 0.01em);
            }
            @include xl {
                @include set-font(18px, 28px, 0.02em);
                @include paddingY(14px);
            }
        }

        .a4-select {
            margin-top: 20px;
            .select2 {
                max-width: none;
            }
            .select2-selection__rendered {
                @include set-font(14px, 28px);
                @include xl {
                    @include set-font(16px, 25px);
                }
            }
        }
        @include sm {
            padding: 0;
        }
    }
    .screen:not(:first-child) {
        .a4-find-plan-modal-title {
            text-align: left;
            margin-bottom: 30px;
            @include sm {
                text-align: center;
                margin-bottom: 50px;
            }
            @include xl {
                text-align: center;
                margin-bottom: 70px;
            }
        }
        .a4-btn.-primary {
            width: auto;
            margin-right: 0px;
        }
        .a4-find-plan-modal-btn-group {
            display: flex;
            justify-content: center;
        }
    }
}
