@mixin size($size) {
    width: $size;
    height: $size;
}

@mixin paddingY($top, $bottom: null) {
    @if($bottom) {
        padding-top: $top;
        padding-bottom: $bottom;
    } @else {
        padding-top: $top;
        padding-bottom: $top;
    }
}

@mixin paddingX($left, $right: null) {
    @if($right) {
        padding-left: $left;
        padding-right: $right;
    } @else {
        padding-left: $left;
        padding-right: $left;
    }
}

@mixin marginY($top, $bottom: null) {
    @if($bottom) {
        margin: {
            top: $top;
            bottom: $bottom;
        }
    } @else {
        margin: {
            top: $top;
            bottom: $top;
        }
    }
}

@mixin marginX($left, $right: null) {
    @if($right) {
        margin: {
            left: $left;
            right: $right;
        }
    } @else {
        margin: {
            left: $left;
            right: $left;
        }
    }
}

@mixin inset($top, $right: null, $bot: null, $left: null) {
    @if($right == null) {
        top: $top;
        bottom: $top;
        right: $top;
        left: $top;
    }
    @if($bot == null) {
        top: $top;
        bottom: $top;
        right: $right;
        left: $right;
    }
    @if($left == null) {
        top: $top;
        bottom: $bot;
        right: $right;
        left: $right;
    } @else {
        top: $top;
        bottom: $bot;
        right: $right;
        left: $left;
    }
}

@mixin placeholder() {
    opacity: 1;
    @include set-font(14px, 25px);
    color: rgba(var(--secondaryR), var(--secondaryG), var(--secondaryB), 0.2);
    font-weight: normal;
    transition: opacity 0.2s;

    @include md {
        @include set-font(16px, 25px);
    }
}

@mixin container($sm, $md, $xl, $lg: null) {
    @include paddingX(20px);
  
    @include media(sm) {
      @include paddingX(0px);
      width: $sm;
      margin: auto;
    }
  
    @include media(md) {
      width: $md;
    }
  
    @include media(lg) {
      @if $lg {
        width: $lg;
      } @else {
        width: $md;
      }
    }
  
    @include media(xl) {
      width: $xl;
    }
}

@mixin shadow() {
    &::before {
        content: "";
        position: absolute;
        width: 100%;
        max-width: 90%;
        height: calc(100% - 10px);
        top: 23px;
        left: 43px;
        z-index: -1;
        background: rgba(24, 40, 72, 0.15);
        opacity: 0.6;
        filter: blur(100px);
        border-radius: 38px;
        @media screen and (-ms-high-contrast: active),
            (-ms-high-contrast: none) {
            top: 0;
            left: 0;
            box-shadow: 23px 13px 100px 0px rgba(0, 0, 0, 0.15);
        }
    }
}

@mixin media($screen) {
    @if $screen == sm {
      @media (min-width: screen(sm)) { @content }
    } @else if $screen == md {
      @media (min-width: screen(md)) { @content }
    } @else if $screen == lg {
      @media (min-width: screen(lg)) { @content }
    } @else {
      @media (min-width: screen(xl)) { @content }
    }
}
  
@mixin sm() {
    @media (min-width: screen(sm)) { @content }
}
@mixin md() {
    @media (min-width: screen(md)) { @content }
}
@mixin lg() {
    @media (min-width: screen(lg)) { @content }
}
@mixin xl() {
    @media (min-width: screen(xl)) { @content }
}

@mixin listHeight($itemHeight, $topMargin, $itemsPerColumn) {
    height: $itemHeight * $itemsPerColumn + $topMargin * $itemsPerColumn;
}