@import 'variables.scss';
@import 'functions.scss';
@import 'mixins.scss';
@import 'fonts.scss';
@import 'placeholders.scss';

.a4-plan {
    $root: &;

    &-container {
        padding: 40px 0 35px 0;
        margin-bottom: 26px;
        min-height: 456px;
        display: flex;

        &.-individual-course {
            min-height: 356px;
        }
        &.-course-bundle {
            min-height: 426px;
        }

        transition-duration: 0.4s;
        transition-property: opacity height max-height;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &-item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;

        transition: all 0.4s;
    }

    &-price {
        color: var(--secondary);
        font-weight: bold;

        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &-digits {
            display: flex;
            align-items: flex-start;
        }
        line-height: 38px;

        &-int {
            font-size: 36px;
        }

        &-float {
            font-size: 22px;
            line-height: 28px;
        }

        margin-top: 20px;
        margin-left: 10px;

        &.-group-plan {
            margin-left: 15px
        }
    }

    &-title.-individual-course {
        &:hover {
            cursor: pointer;
        }
    }

    &-description {
        $description: &;
        @extend %description;
        color: rgba(var(--secondaryR), var(--secondaryG), var(--secondaryB), 0.5);

        margin-top: 20px;
    }

    &-advantages {
        display: none;
        flex-direction: column;
        margin-top: 20px;
        margin-bottom: 20px;

        &.-membership {
            display: block;
            max-height: 1000px;
            opacity: 1;
        }
        &.-course-bundle {
            display: block;
            margin-top: 30px;
            margin-bottom: 20px;

            svg {
                width: 10px;
                height: 8px;
                margin-right: 10px;
            }
        }
        &.-webinar {
            display: block;
            margin-top: 30px;
        }

        &-item {
            transition-duration: 0.4s;
            transition-property: opacity height max-height;
            &-text {
                color: var(--secondary);
                @include set-font(13px, 24px);
                font-weight: 600;
            }
            &-icon {
                display: flex;
                margin-right: 12px;

                img {
                    @include size(15px);
                    margin-right: 10px;

                    color: var(--primary);
                }
            }
            &.-membership {
                display: flex;
                align-items: center;
                margin-bottom: 20px;
            }
            &.-course-bundle {
                max-height: 1000;
                display: flex;
                align-items: baseline;
            }
            &.-webinar {
                padding-top: 10px;
            }
            &.-webinar-title {
                width: 100%;
                margin-bottom: 6px;
            }
        }
    }

    &-controls {
        margin-top: 20px;
        margin-right: 10px;

        display: flex;
        justify-content: space-between;
    }

    &-buy-now.a4-btn.-primary {
        height: 55px;
        @include set-font(16px, 24px);
    }
    
    &-learn-more {
        display: none;

        @include set-font(14px, 24px);
        font-weight: bold;
        color: var(--primary);
        cursor: pointer;
        align-self: center;
        margin-right: 30px;

        background: none!important;
        border: none;
        padding: 0!important;

        &:hover {
            text-decoration: underline;
        }
    }

    &-section:last-of-type {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &-section-subsection button {
        display: none;
    }

    @include sm {
        &-container {
            width: 731px;
            min-height: 0;
            margin: 0 auto;
            padding: 60px 50px 50px 50px;
        }

        &-item {
            position: relative;
            min-height: 0;
        }

        &-title {
            padding-right: 120px;
        }

        &-title.-individual-course {
            &:hover {
                cursor: default;
            }
        }

        &-description {
            padding-right: 200px;
            visibility: visible;
            max-height: 1000px;
        }

        &-description.-individual-course {
            max-height: none;
            &:hover {
                cursor: default;
            }
        }

        &-description.-course-bundle {
            padding-right: 200px;

            @include md {
                padding-right: 30px;
            }
        }

        &-price {
            position: absolute;
            top: 0;
            right: 0;

            margin-top: 0;
            margin-right: 20px;
            margin-left: 30px;
        }
        
        &-section-subsection {
            display: flex;
            flex-direction: row-reverse;
            margin-top: 10px;
            button {
                display: flex;
                margin-top: 20px;
            }
        }
        &-advantages {
            display: flex;
            flex-wrap: wrap;
            opacity: 1;

            width: 100%;
            min-height: 55px;
            max-height: 1000px;

            &.-course-bundle {
                display: flex;
                flex-direction: row;
                margin-top: 20px;
            }
            &.-webinar {
                display: flex;
                flex-direction: row;
                margin-top: 40px;
            }
            &.-membership {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                margin-top: 0;
                max-width: 80%;
            }
            &.-individual-course {
                flex-direction: row;
            }

            &-item {
                position: relative;
                visibility: visible;

                display: flex;
                align-items: baseline;
                width: 50%;
                padding-right: 20px;
                svg {
                    color: var(--secondary);
                    margin-right: 10px;
                }

                &-title {
                    background-color: transparent;
                    @include set-font(13px, 24px);
                    margin-bottom: 6px;
                }
                &-value {
                    @include set-font(14px, 26px);
                    font-weight: bold;
                }
                &.-individual-course {
                    flex-direction: column;
                    align-items: flex-start;
                    width: 33%;
                    :last-of-type {
                        padding-right: 0;
                    }
                }
                &.-membership {
                    margin-top: 20px;
                    width: 200px;
                    margin-bottom: 0;
                    padding-right: 40px;
                }
                &.-course-bundle {
                    max-height: 1000px;
                }
                &.-webinar {
                    max-height: 1000px;
                    padding-top: 0px;
                }
            }
        }
        &-controls {
            margin-top: 15px;
            justify-content: flex-start;
            &.-membership {
                display: none;
            }
        }

        &-buy-now.a4-btn.-primary.-bubble {
            display: flex;
            justify-content: center;
            align-items: center;
            @include set-font(14px, 20px);
        }
        &-learn-more {
            margin-left: 48px;
            display: none;
            &.-individual-course {
                display: block;
            }

            &:hover {
                text-decoration: underline;
            }
        }
    }

    @include md {
        &-container {
            border-radius: 30px;
            width: 814px;
            margin-bottom: 36px;
            &:hover {
                background-color: var(--primary-light);
            }
        }

        &-item {
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
        }

        &-section:not(:first-of-type) {
            margin-left: 30px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        &-title {
            display: flex;
            @include set-font(28px, 42px);
            align-items: center;
            padding-right: 0;
        }

        &-description {
            padding-right: 30px;
        }

        &-section {
            &:first-of-type {
                flex: 1;
            }
        }
        &-section:last-of-type {
            justify-content: flex-end;
        }

        &-price {
            margin-right: 0px;
            margin-left: 30px;

            &.-individual-course {
                margin-right: 30px;
            }

            &.-group-plan {
                margin-right: 15px;
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            &-int {
                @include set-font(48px, 54px);
            }

            &-float {
                @include set-font(28px, 40px);
            }
        }
        &-learn-more {
            margin: 0 auto;
            margin-top: 29px;
            @include set-font(16px, 24px);
        }
        &-buy-now.a4-btn.-primary {
            @include set-font(16px, 42px, 0.01em);
            width: fit-content;
        }
        &-advantages {
            width: auto;
            flex: 1;
            margin-top: 40px;
            margin-bottom: 0px;

            &.-membership {
                max-width: 1000px;
            }

            &-item {
                &-text {
                    @include set-font(14px, 26px);
                }
                &.-membership {
                    width: auto;
                }
            }
        }

        &-controls {
            margin-top: 0;
            width: auto;
            flex-direction: column;
            align-items: center;
            &.-membership {
                display: flex;
            }
        }
        &-section:last-of-type {
            width: 120px;
            min-height: 120px;
        }
        &-section-subsection {
            flex-direction: row;
            margin-top: 20px;
            .a4-plan-buy-now.a4-btn.-primary.-bubble {
                display: none;
            }
        }
    }

    @include lg {
        &-price {
            margin-right: 10px;

            &.-individual-course {
                margin-right: 25px;
            }
        }        
    }

    @include xl {
        &-container {
            width: 1194px;
            padding: 90px 70px;
        }

        &-item {
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
        }
        &-title {
            @include set-font(32px, 32px);
        }
        &-description {
            margin-top: 24px;
        }
        &-advantages {
            margin-top: 40px;
            &-item {
                &-text {
                    @include set-font(16px, 28px);
                }
            }
        }
        &-section:not(:first-of-type) {
            margin-left: 135px;

            &.-group-plan {
                margin-left: 120px;
            }

            &.-webinar {
                margin-left: 115px;
            }
        }
        &-price {
            margin-right: 0px;

            &.-individual-course {
                margin-right: 20px;
            }

            &.-group-plan {
                margin-right: -15px
            }
            
            font-feature-settings: "pnum" on, "lnum" on;
            &-int {
                @include set-font(64px, 64px);
            }

            &-float {
                @include set-font(36px, 42px);
            }
        }
        &-learn-more {
            @include set-font(18px, 24px);
        }
        &-buy-now.a4-btn.-primary.-bubble {
            @include set-font(18px, 20px);
        }
    }
}
