@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700;800&display=swap');

@mixin set-font($fontSize, $lineHeight, $spacing: normal) {
    font-size: $fontSize;
    line-height: $lineHeight !important;
    letter-spacing: $spacing;
};

.text-xl {
    @include set-font(30px, 38px, -0.01em);
    color: var(--secondary);

    @include sm {
        @include set-font(48px, 62px, -0.02em);
    }
    @include md {
        @include set-font(52px, 72px, -0.02em);
    }
    @include xl {
        @include set-font(64px, 86px, -0.02em);
    }
}

.text-lg {
    @include set-font(24px, 32px, -0.02em);
    color: var(--secondary);

    @include md {
        @include set-font(28px, 42px, -0.02em);
    }
    @include xl {
        @include set-font(32px, 47px, -0.02em);
    }
}

.text-md {
    @include set-font(18px, 28px, 0.02em);
    color: var(--secondary);

    @include md {
        @include set-font(20px, 30px, normal);
    }
    @include xl {
        @include set-font(24px, 34px, normal);
    }
}

.text-sm {
    @include set-font(14px, 26px, normal);
    color: var(--secondary);

    @include md {
        @include set-font(16px, 28px, 0.01em);
    }
    @include xl {
        @include set-font(18px, 32px, normal);
    }
}

.text-xs {
    @include set-font(13px, 26px, normal);
    color: var(--secondary);

    @include md {
        @include set-font(14px, 28px, 0.01em);
    }
    @include xl {
        @include set-font(16px, 32px, normal);
    }
}