@import 'variables.scss';
@import 'functions.scss';
@import 'mixins.scss';
@import 'fonts.scss';
@import 'placeholders.scss';

.a4-footer {
    $root: &;
    position: relative;

    &-container {
        @include container($sm: 730px, $md: 983px, $xl: 1440px);
    }

    &-filler {
        @extend %a4-filler;
        background: var(--secondary);
    }

    &-content {
        @include paddingY(80px);

        @include md {
            display: flex;
        }
    }

    &-left {
        @include sm {
            display: flex;
            justify-content: space-between;
        }

        @include md {
            display: block;
        }
    }

    &-right {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        margin-top: 70px;
        margin-right: 20px;
    }

    &-list {
        min-width: 125px;
        &.-email,
        &-no-wrap {
            white-space: pre;
        }

        ul {
            li a {
                transition: color 0.2s ease-in-out;

                &:hover {
                    color: var(--primary);
                }
            }

            @include set-font(13px, 34px);
            color: #fff;

            @include xl {
                @include set-font(16px, 42px);
            }
        }

        &-item-nowrap {
            white-space: nowrap;
        }

        &-title {
            margin-bottom: 10px;

            h4 {
                @include set-font(16px, 28px);
                color: rgba(#fff, 0.3);
                font-weight: 500;

                @include xl {
                    @include set-font(18px, 42px);
                }
            }
        }
    }

    &-logo {
        width: 205px;
        height: 52px;

        img {
            width: 100%;
            object-fit: cover;
        }
    }

    &-copyright {
        font-size: 13px;
        line-height: 20px;
        color: rgba(255, 255, 255, 0.4);

        margin-top: 40px;
    }

    &-app-store {
        margin-top: 30px;

        button {
            align-self: flex-start;
            margin-right: 20px;
        }

        button + button {
            margin-top: 20px;
        }
    }

    @include sm {
        &-app-store {
            display: flex;
            align-items: center;

            margin-top: 0;
            margin-bottom: 30px;

            button {
                align-self: center;
                margin-right: 0;
            }

            button + button {
                margin-top: 0px;
                margin-left: 20px;
            }
        }

        &-right {
            margin-top: 85px;
            justify-content: flex-start;

            #{$root}-list {
                min-width: 156px;

                ul {
                    max-width: 239px;
                }

                &:first-of-type {
                    margin-right: 36px;
                }
                &:not(:last-of-type) {
                    margin-right: 44px;
                }
            }
        }
    }

    @include md {
        &-left {
            margin-right: 60px;
        }
        &-right {
            margin-top: 0px;
            margin-right: 0px;
            flex: 1;
            flex-wrap: nowrap;
            #{$root}-list {
                min-width: auto;
                &:not(:last-of-type) {
                    margin-right: 36px;
                    width: 174px;
                }
                &:first-of-type {
                    margin-right: 79px;
                    width: 90px;
                }
            }
        }

        &-app-store {
            margin-top: 60px;
            margin-bottom: 0px;
        }
    }

    @include lg {
        &-logo {
            width: 202px;
            height: 62px;
        }
    }

    @include xl {
        &-logo {
            width: 252px;
            height: 72px;
        }
        &-right {
            #{$root}-list {
                ul {
                    max-width: 302px;
                }
                &:not(:last-of-type) {
                    margin-right: 102px;
                    width: 214px;
                }
                &:first-of-type {
                    margin-right: 143px;
                    width: 103px;
                }
            }
        }

        &-app-store {
            margin-top: 100px;
        }
    }
}
