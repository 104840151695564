@import 'variables.scss';
@import 'functions.scss';
@import 'mixins.scss';
@import 'fonts.scss';
@import 'placeholders.scss';

.a4-radio {
    position: relative;
    margin-right: 15px;
    padding-right: 15px;
    cursor: pointer;

    @include sm {
        position: static;
    }

    input {
        position: absolute;
        opacity: 0;
    }

    &-button {
        position: absolute;
        left: 0px;
        height: 20px;
        width: 20px;
        background-color: #fff;
        border-radius: 50%;
        border: 1px solid var(--primary);

        margin-top: 2px;

        @include sm {
            top: 4px;
            margin-top: 0px;
        }
    }

    .a4-radio-button:after {
        content: "";
        position: absolute;
        display: none;
    }
    
    & input:checked ~ .a4-radio-button:after {
        display: block;
    }

    & .a4-radio-button:after {
        top: 3px;
        left: 3px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: var(--primary);
    }
}