.a4-auth {
    $root: &;

    &-container {
        @include container($sm: 730px, $md: 983px, $xl: 1437px);
        padding: 0;
    }

    &-item {
        position: relative;
        &-title {
            margin-bottom: 40px;
            @include set-font(24px, 32px, -0.02em);
            font-weight: 800;

            &.-reset {
                margin-bottom: 16px;
            }
        }

        &-container {
            position: relative;
        }

        &-content {
            position: relative;
            background-color: #ffffff;
            padding: 115px 18px 30px 18px;
            min-height: 415px;

            &.-success {
                figure {
                    opacity: 1;
                }
                br {
                    display: none;
                }
                #{$root}-form {
                    display: none;
                }
            }

            &.confirm-email {
                min-height: 379px;
            }
        }

        &-shadow {
            position: absolute;
            background: rgba(24, 40, 72, 0.15);
            opacity: 0.6;
            @media screen and (-ms-high-contrast: active),
                (-ms-high-contrast: none) {
                display: none;
            }
            filter: blur(60px);
            border-radius: 38px;
            width: calc(100% - 29px);
            height: 100%;

            right: 0;
            top: 19px;
        }
    }

    &-form-control {
        display: flex;
        justify-content: center;
        align-items: center;

        a {
            @include set-font(14px, 20px);
            margin-left: 14px;
            &:hover {
                text-decoration: underline;
            }
        }

        &.-join {
            justify-content: center;
        }
    }

    &-additional {
        text-align: center;

        @include set-font(13px, 24px);
        color: rgba(24, 40, 72, 0.4);
        padding: 20px 0 60px 0;

        a {
            font-weight: 600;
            //cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }
    }
    p {
        @include set-font(14px, 26px, 0.01em);
        margin-bottom: 40px;
    }
    .a4-input {
        margin-bottom: 34px;
        &:last-of-type {
            margin-bottom: 30px;
        }
    }
    .a4-form-error {
        margin-top: 0px;
    }

    figure {
        position: absolute;
        bottom: 61px;
        left: calc(50% - 35px);

        margin: 0;

        display: flex;
        align-items: center;
        justify-content: center;

        width: 70px;
        height: 70px;
        border-radius: 35px;

        background-color: #f4fdfc;
        color: var(--primary);

        opacity: 0;

        transition: inherit;
        transition-delay: 0.4s;
        svg {
            width: 22px;
        }
    }

    @include sm {
        &-container {
            background-color: rgba(var(--primaryR), var(--primaryG), var(--primaryB), 0.05);
            border-radius: 40px;
            margin-top: 110px;
            margin-bottom: 50px;

            &.-login {
                min-height: calc(50vh - 110px);
                padding-top: 55px;
            }

            &.-register {
                min-height: calc(70vh - 110px);
                padding-top: 55px;
            }
        }

        &-item {
            &-title {
                text-align: center;
                margin-bottom: 48px;
                padding: 0 40px;
            }
            &-container {
                margin: 0 auto;
                width: 508px;
            }
            &-content {
                position: relative;
                background-color: unset;
                padding: 40px 0 40px 0;
                background-color: #ffffff;
                border-radius: 30px;

                min-height: 363px;

                &.-success br {
                    display: unset;
                }

                &.confirm-email {
                    min-height: 293px;
                }
            }
            &-shadow {
                right: 25px;
                top: 24px;
            }
        }
        &-additional {
            @include set-font(14px, 24px);
            padding: 24px 0;
        }
        &-form {
            padding: 0 40px;
        }

        &-form-control {
            a {
                margin-left: 24px;
            }

            &.-join {
                justify-content: center;
            }
        }
        p {
            text-align: center;
            margin-bottom: 28px;
            padding: 0 40px;
        }
        button b {
            @include set-font(14px, 24px);
        }
        .a4-input:last-of-type {
            margin-bottom: 40px;
        }

        figure {
            bottom: 77px;
        }
    }

    @include md {
        &-container {
            margin-top: 137px;
            margin-bottom: 70px;

            &.-login {
                min-height: unset;
                padding-top: 50px;
            }

            &.-register {
                padding-top: 50px;
            }
        }

        &-item {
            &-content {
                min-height: 396px;

                &.confirm-email {
                    min-height: 326px;
                }
            }
            &-title {
                @include set-font(28px, 38px, -0.02em);
                margin-bottom: 54px;
            }
        }

        p {
            @include set-font(15px, 28px, 0.01em);
        }

        &-form-control a {
            @include set-font(16px, 42px);
            margin-left: 24px;
        }
        button b {
            @include set-font(16px, 24px);
        }

        figure {
            bottom: 80px;
        }
    }
    @include lg {
        &-container {
            margin-top: 130px;

            &.-login {
                padding-top: 65px;
            }

            &.-register {
                padding-top: 65px;
            }
        }
        &-item-content {
            min-height: 446px;
        }
        p {
            @include set-font(18px, 32px, 0.02em);
        }
        &-form-control a {
            @include set-font(18px, 42px);
        }

        button b {
            @include set-font(18px, 24px);
        }

        figure {
            bottom: 96px;
        }
    }
    @include xl {
        &-item {
            &-content {
                min-height: 449px;

                &.confirm-email {
                    min-height: 379px;
                }
            }
            &-title {
                @include set-font(32px, 44px, -0.02em);
            }
        }
        &-container {
            padding-top: 70px;
            margin-top: 150px;
        }
    }
}
