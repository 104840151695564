@import 'variables.scss';
@import 'functions.scss';
@import 'mixins.scss';
@import 'fonts.scss';
@import 'placeholders.scss';

.a4-faq {
    &-container {
        @include container($sm: 605px, $md: 814px, $xl: 1192px);
    }

    &-page-title {
        @extend %page-title;
    }

    &-topics {
        margin-top: 50px;
        @include marginY(50px, 80px);

        &-accordion {
            &-item {
                $item: &;
                position: relative;

                @include paddingY(30px);

                &:not(:last-child) {
                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                }
                transition: border-bottom-color 0.3s;

                &-head {
                    display: flex;
                    align-items: center;

                    cursor: pointer;

                    &-title {
                        width: 80%;
                        @extend .text-md;
                        font-weight: bold;
                    }

                    &-icon {
                        display: flex;
                        justify-content: flex-end;
                        width: 20%;
                    }

                    .a4-dynamic-plus {
                        margin-right: 20px;
                    }
                }

                &-content {
                    overflow: hidden;

                    &-answer {
                        transition: margin-top 0.5s;
                        display: none;

                        @extend .text-sm;
                        color: rgba(var(--secondaryR), var(--secondaryG), var(--secondaryB), 0.5);
                    }

                    b {
                        color: #000;
                    }

                    @include sm {
                        @include paddingX(64px);

                        &-answer {
                            margin-top: 40px;
                        }
                    }
                    @include md {
                        @include paddingX(80px);
                    }

                    @include xl {
                        @include paddingX(70px);
                    }
                }

                &-fill {
                    position: absolute;
                    top: 0;
                    right: 0;
                    left: 0;
                    bottom: 0;
                    max-width: 100vw;
                    margin: 0 calc(-50vw + 50%);
                    z-index: -1;

                    @include sm {
                        margin: auto;
                        max-width: unset;
                        left: -64px;
                        right: -64px;
                        border-radius: 30px;
                    }

                    @include md {
                        margin: auto;
                        max-width: unset;
                        left: -90px;
                        right: -90px;
                        border-radius: 30px;
                    }

                    @include xl {
                        left: -122px;
                        right: -122px;
                    }
                }

                &.-opened {
                    h3 {
                        color: var(--primary) !important;
                    }
                    #{$item}-content-answer {
                        margin-top: 20px;
                    }
                    #{$item}-fill {
                        background-color: var(--primary-light);
                        top: -2px;
                        bottom: -2px;
                    }
                    & .a4-dynamic-plus {
                        @extend .-active;
                    }
                }

                @include sm {
                    @include paddingY(50px);
                }
            }
        }

        @include sm {
            @include marginY(70px, 113px);
        }
        @include md {
            @include marginY(110px, 200px);
        }
    }

    &-question {
        &-content {
            padding-bottom: 100px;

            @include md {
                padding-bottom: 200px;
            }
        }

        &-card {
            position: relative;
            &::after {
                content: "";
                @extend %a4-filler;
                background: #ffffff;
                margin: 0 -20px;
            }
            @include shadow();

            &-content {
                position: relative;

                @include paddingY(60px);

                @include sm {
                    @include paddingX(30px);
                }
                @include xl {
                    @include paddingX(60px);
                }
            }

            &-title {
                h2 {
                    @extend .text-lg;
                    font-weight: 800;

                    @include sm {
                        text-align: center;
                    }
                }
            }

            &-form {
                max-width: 394px;
                margin-top: 30px;
                &-section.-success {
                    display: flex;
                    flex-flow: column;
                    justify-content: center;

                    transition: opacity 0.4s;
                    opacity: 0;
                    touch-action: none;
                    pointer-events: none;

                    position: absolute;
                    bottom: 0;
                    top: 0;
                    left: 0;
                    right: 0;
                    z-index: 1;

                    text-align: center;
                    background: #fff;
                    border-radius: 30px;
                    figure {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        margin: 0 auto;
                        width: 70px;
                        height: 70px;
                        border-radius: 35px;

                        background-color: #f4fdfc;
                        color: var(--primary);

                        svg {
                            width: 22px;
                        }
                    }
                    h3 {
                        @include set-font(24px, 32px, -0.02em);
                        font-weight: 800;
                        margin: 30px 0 10px 0;
                    }
                    p {
                        @include set-font(14px, 26px, 0.02em);
                    }
                    &.-visible {
                        opacity: 1;
                        touch-action: unset;
                        pointer-events: unset;
                    }
                    @include md {
                        h3 {
                            @include set-font(28px, 42px, -0.02em);
                        }
                        p {
                            @include set-font(16px, 30px, 0.02em);
                        }
                    }
                }
                textarea {
                    resize: none;
                    height: 120px;
                }
                @include sm {
                    .a4-form-group {
                        height: 60px;
                    }
                }
                .a4-form-group + .a4-form-group {
                    margin-top: 24px;

                    @include sm {
                        margin-top: 0;
                        margin-left: 10px;
                    }
                    @include md {
                        margin-top: 0;
                        margin-left: 20px;
                    }
                }

                .a4-form-control {
                    margin-top: 30px;
                    position: relative;
                    z-index: 1;

                    @include sm {
                        margin-top: 0;
                        margin-left: 10px;
                    }
                    @include md {
                        margin-top: 0;
                        margin-left: 30px;
                    }
                }

                @include sm {
                    margin-top: 44px;
                    max-width: 100%;

                    form {
                        section {
                            display: flex;
                            align-items: center;
                        }

                        textarea,
                        .a4-input input {
                            height: 61px;
                        }
                    }
                }

                @include md {
                    form {
                        textarea {
                            width: 320px;
                        }
                    }
                }
                @include xl {
                    form {
                        textarea {
                            width: 547px;
                        }
                    }
                }
            }

            @include sm {
                width: 604px;
                margin: auto;
                &::after {
                    margin: 0;
                    border-radius: 30px;
                    background-color: #fff;
                }
                background: transparent;
            }

            @include md {
                width: 814px;
            }

            @include xl {
                width: 1194px;
            }
        }
    }
}

.a4-dynamic-plus {
    position: relative;
  
    width: 24px;
    height: 24px;
  
    transition: transform 0.45s ease-in-out;
    transition-timing-function: cubic-bezier(0.61, 0.04, 0.17, 1.32);
  
    cursor: pointer;
  
    &:before, &:after {
      content: '';
      position: absolute;
  
      width: 100%;
      height: 3px;
      border-radius: 2px;
      background: var(--primary);
    }
  
    &:before {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  
    &:after {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(90deg);
      transform-origin: center;
      opacity: 1;
      transition: opacity 0.3s ease-in-out;
    }
  
    &.-active {
      transform: rotateZ(180deg);
  
      &:after {
        opacity: 0;
      }
    }
  }