@import 'variables.scss';
@import 'functions.scss';
@import 'mixins.scss';
@import 'fonts.scss';
@import 'placeholders.scss';

.a4-course-catalog {
    $root: &;

    &-container {
        @include paddingX(20px);

        @include sm {
            max-width: 604px;
            margin: auto;
        }

        @include md {
            max-width: 814px;
            margin: auto;
        }

        @include xl {
            max-width: 1194px;
            margin: auto;
        }
    }

    &-title {
        padding-top: 130px;

        background: var(--primary-light);

        h1 {
            @extend .text-xl;
            font-weight: bold;
        }
    }

    &-filter-panel {
        background: var(--primary-light);
        border-radius: 0 0 30px 30px;

        &-container {
            @extend .a4-course-catalog-container;
        }

        &-content {
            display: flex;
            width: 100%;
            @include paddingY(70px, 40px);
        }

        &-section-1 {
            width: 100%;

            #filter-menu-btn {
                background: #fff;

                width: 100%;

                @include set-font(14px, 25px);
                @include paddingY(15px);
                @include paddingX(35px);
                border: 1px solid #e2e2e2;
                border-radius: 10px;

                i {
                    display: inline-flex;
                    margin-right: 10px;
                }

                &-items-count {
                    opacity: 0.3;
                }

                @include md {
                    @include paddingY(17px);
                }

                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &-section-2 {
            display: none;

            #exp-date-filter,
            #category-filter {
                display: none;
            }

            @include sm {
                display: flex;
                flex: 1;
            }

            @include md {
                #exp-date-filter,
                #category-filter {
                    display: block;
                }
            }
        }

        &-section-3 {
            display: none;

            @include md {
                display: flex;
            }
        }
    }

    &-list {
        &-container {
            @include paddingX(20px);

            @include sm {
                max-width: 704px;
                margin: auto;
            }

            @include md {
                max-width: 814px;
                margin: auto;
            }

            @include xl {
                max-width: 1194px;
                margin: auto;
            }
        }

        &-content {
            @include paddingY(40px);
            @include sm {
                @include paddingY(60px);
            }
        }

        &-items {
            $negative-margin: 36px;

            &-item {
                $item: &;
                cursor: pointer;
                display: block;
                @include paddingY(30px);

                & + & {
                    margin-top: 26px;
                }

                &-image {
                    img {
                        width: 92px;
                        height: 92px;
                    }

                    @include sm {
                        margin-right: 30px;

                        img {
                            width: 156px;
                            height: 156px;
                        }
                    }

                    @include sm {
                        img {
                            width: 133px;
                            height: 133px;
                        }
                    }

                    @include xl {
                        img {
                            width: 210px;
                            height: 210px;
                        }
                    }
                }

                &-details {
                    margin-top: 20px;

                    #{$item}-title {
                        h4 {
                            @extend .text-lg;
                            font-weight: 800;
                        }
                    }

                    #{$item}-info {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        margin-top: 20px;

                        @include sm {
                            flex-direction: row;
                            align-items: center;
                        }

                        @include xl {
                            margin-top: 40px;
                        }

                        &-item {
                            display: flex;
                            flex-direction: row;
                            align-items: center;

                            &.-right {
                                padding-top: 10px;
                                white-space: nowrap;

                                @include sm {
                                    padding-top: 0px;
                                    padding-left: 40px;
                                }
                            }
                        }

                        &-label {
                            @include set-font(14px, 24px);
                            font-weight: 500;
                            color: var(--primary);

                            @include md {
                                @include set-font(16px, 26px);
                            }

                            @include xl {
                                @include set-font(18px, 28px);
                            }
                        }

                        span {
                            @include set-font(16px, 24px);
                            font-weight: 600;
                            color: var(--secondary);
                            padding-left: 10px;

                            @include md {
                                @include set-font(18px, 26px);
                            }

                            @include xl {
                                @include set-font(20px, 28px);
                            }
                        }
                    }

                    #{$item}-container {
                        padding-top: 10px;
                    }

                    #{$item}-description {
                        @extend %description;
                        margin-top: 10px;
                    }

                    @include sm {
                        margin-top: 0;
                    }
                }

                &-control {
                    margin-top: 15px;

                    span {
                        @extend .text-sm;
                        position: relative;
                        font-weight: bold;
                        color: var(--primary);

                        &:before {
                            content: "";
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            transform-origin: center;
                            width: 120%;
                            height: 200%;
                        }

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }

                &-description {
                    /* autoprefixer: off */
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 4;
                    overflow: hidden;
                }

                @include sm {
                    // max-width: 732px;
                    @include paddingY(50px);
                    // @include paddingX(10px);
                    display: flex;
                    // margin-left: $negative-margin;
                    margin-top: $negative-margin;

                    & + & {
                        margin-top: $negative-margin;
                    }

                    &-description {
                        /* autoprefixer: off */
                        -webkit-line-clamp: 3;
                    }
                }

                @include md {
                    // min-width: 474px;
                    // width: 474px;
                    @include paddingY(60px);
                    @include paddingX(40px);

                    border-radius: 30px;

                    &:hover {
                        background: var(--primary-light);
                    }

                    &-description {
                        /* autoprefixer: off */
                        -webkit-line-clamp: 4;
                    }
                }
                @include lg {
                    &-description {
                        /* autoprefixer: off */
                        -webkit-line-clamp: 7;
                    }
                }

                @include xl {
                    // min-width: 702px;
                    // width: 702px;
                    @include paddingY(90px);
                    @include paddingX(70px);
                    &-description {
                        /* autoprefixer: off */
                        -webkit-line-clamp: 5;
                    }
                }
            }

            @include md {
                //display: flex;
                //flex-wrap: wrap;
                //justify-content: center;

                display: flex;
                flex-direction: column;

                margin-top: -$negative-margin;
                // margin-left: -$negative-margin;
            }
        }
    }

    @include sm {
        &-container {
            @include paddingX(0);
        }

        &-filter-panel {
            border-radius: 0 0 40px 40px;

            &-content {
                display: flex;
                @include paddingY(97px, 40px);

                #filter-menu-btn {
                    flex: 0;
                    min-width: 220px;
                    margin: 0;
                }
            }

            &-section-1 {
                width: auto;
                margin-right: 10px;
            }

            &-section-2 {
                margin-right: 25px;

                .filter-item + .filter-item {
                    margin-left: 10px;
                }

                div.a4-input.-search {
                    height: 61px;
                    display: block;
                    flex: 1;
                }
            }

            &-section-3 {
                #{$root}-filter-panel-section-title {
                    display: flex;
                    align-items: center;
                    margin-right: 15px;

                    h4 {
                        @include set-font(14px, 25px);
                        font-weight: bold;
                        color: var(--secondary);

                        &:after {
                            content: ":";
                        }
                    }
                }
            }

            &-menu {
                &-top {
                    margin-bottom: 30px;
                }

                &-title {
                    h4 {
                        @include set-font(20px, 30px);
                    }
                }

                &-cancel {
                    right: 30px;
                }

                &-search {
                    display: none;
                }

                &-items {
                    &-item {
                        &.-active {
                            &:after {
                                right: 57px;
                            }
                        }
                    }
                }
            }
        }
    }

    @include md {
        &-filter-panel {
            &-content {
                @include paddingY(80px, 40px);
            }

            &-section-2 {
                margin-right: 25px;

                .a4-select {
                    width: 210px;
                }
            }

            &-section-3 {
                .a4-select {
                    width: 220px;
                }
            }
        }
    }

    @include lg {
        &-filter-panel {
            border-radius: 0 0 100px 100px;
        }
    }

    @include xl {
        &-title {
            padding-top: 200px;
        }

        &-filter-panel {
            &-section-2 {
                #category-filter {
                    flex: 0 1 210px;
                }

                #exp-date-filter {
                    flex: 0 1 210px;
                }

                .filter-item + .filter-item {
                    margin-left: 20px;
                }

                .a4-select {
                    width: 210px;
                }

                margin-right: 137px;
            }

            &-section-3 {
                .a4-select {
                    width: 210px;
                }
            }
        }
    }
}
