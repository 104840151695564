@import 'variables.scss';
@import 'functions.scss';
@import 'mixins.scss';
@import 'fonts.scss';
@import 'placeholders.scss';


.a4-main-container {
    @include container($sm: 604px, $md: 794px, $xl: 1040px);
}

.a4-appstore {
    &-container {
        @extend .a4-main-container;
    }

    &-content {
        @include paddingY(0px, 80px);

        @include sm {
            display: flex;
            justify-content: space-between;
            @include paddingY(90px, 124px);
        }

        @include md {
            @include paddingY(100px, 118px);
        }

        @include lg {
            @include paddingY(93px, 116px);
        }

        @include xl {
            @include paddingY(200px, 240px);
        }
    }

    &-info {
        @include sm {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    &-title {
        h2 {
            padding: 0 30px;
            
            @extend .text-lg;
            font-weight: 800;
            color: var(--secondary);

            text-align: center;

            @include sm {
                text-align: left;
                padding: 0;
                margin-right: 150px;
            }
        }
    }

    &-description {
        @extend %description;
        text-align: center;

        @include sm {
            text-align: left;
            max-width: 284px;
        }
        @include md {
            max-width: 374px;
        }
        @include xl {
            max-width: 449px;
        }
    }

    &-control {
        display: flex;
        justify-content: center;
        //flex-direction: column;
        margin-top: 40px;

        &-item {
            text-align: center;
        }

        &-item + &-item {
            //margin-top: 16px;
            margin-left: 16px;
        }

        @include sm {
            margin-top: 50px;
            flex-direction: row;
            justify-content: flex-start;

            button {
                margin: unset;
            }

            &-item + &-item {
                margin-top: 0;
                margin-left: 14px;
            }
        }
        @include xl {
            margin-top: 60px;
        }
    }

    &-gadget {
        display: none;

        height: 377px;

        //border: 5px solid #000;
        //border-radius: 20px;

        svg {
            width: 138px;
            height: 26px;
        }

        img {
            height: 100%;
            width: 100%;
        }

        @include sm {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        @include md {
            height: 418px;
            //border-radius: 27px;
        }
        @include xl {
            height: 547px;
            //border-radius: 30px;
        }
    }
}