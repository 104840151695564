@import 'variables.scss';
@import 'functions.scss';
@import 'mixins.scss';
@import 'fonts.scss';
@import 'placeholders.scss';

.a4-help-and-support {
    &-container {
        @include container($sm: 605px, $md: 814px, $xl: 1192px);
    }

    &-page-title {
        @extend %page-title;
    }

    &-contacts {
        @include paddingY(80px, 100px);

        &-list {
            $itemsPerColumn: 3;
            $margin-top: 26px;
            margin-top: -$margin-top;

            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;

            &-item {
                width: 100%;

                border-radius: 20px;
                background: var(--primary-light);

                padding: 30px;
                margin-top: $margin-top;

                &-label {
                    @extend .text-xs;
                    color: rgba(var(--secondaryR), var(--secondaryG), var(--secondaryB), 0.5);
                }
                &-value {
                    @extend .text-sm;
                }
                &-icon {
                    display: none;

                    svg {
                        font-size: 28px;
                    }
                    .-world {
                        width: 25px;
                        height: 25px;
                    }
                    .-phone {
                        width: 23px;
                        height: 37px;
                    }
                    .-letter {
                        width: 31px;
                        height: 26px;
                    }
                    .-social {
                        width: 33.1px;
                        height: 30.5px;
                    }
                    .-printer {
                        width: 33.5px;
                        height: 31.5px;
                    }
                    .-location {
                      width: 21.5px;
                      height: 33px;
                  }
                }
            }

            @include sm {
                $itemHeight: 137px;
                @include listHeight($itemHeight, $margin-top, $itemsPerColumn);

                &-item {
                    width: 284px;
                    height: $itemHeight;
                    max-width: unset;
                }
            }

            @include md {
                $margin-top: 36px;
                $itemHeight: 150px;
                margin-top: -$margin-top;
                @include listHeight($itemHeight, $margin-top, $itemsPerColumn);

                &-item {
                    margin-top: $margin-top;
                    width: 390px;
                    height: $itemHeight;

                    display: flex;
                    align-items: center;

                    &-info {
                        flex: 1;
                        padding-right: 18px;
                    }

                    &-icon {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: relative;
                        @include size(70px);

                        &:before {
                            content: "";
                            @include size(100%);
                            background: var(--primary);
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            transform-origin: center;
                            border-radius: 50%;
                            opacity: 0.2;
                        }
                    }
                }
            }

            @include xl {
                &-item {
                    width: 579px;
                    border-radius: 30px;

                    &-icon {
                        @include size(80px);
                    }
                }
            }
        }

        @include sm {
            @include paddingY(100px);
        }

        @include md {
            @include paddingY(110px, 200px);
        }
    }
}
