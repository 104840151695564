.a4-input {
    $root: &;

    position: relative;

    transition-property: transform, opacity, border;
    transition-duration: 0.4s;

    textarea {
        height: 6em;
    }

    label {
        position: absolute;
        bottom: 100%;
        left: 0;

        color: rgba(var(--secondaryR), var(--secondaryG), var(--secondaryB), 0.6);

        transition: inherit;
        opacity: 0;
        padding-bottom: 10px;

        transform: translateY(2px);
        @include set-font(13px, 14px);
    }

    input,
    textarea {
        width: 100%;

        background: #fff;
        border: 1px solid #e2e2e2;
        border-radius: 10px;

        @include paddingY(15px);
        @include paddingX(20px);

        @include set-font(14px, 25px);
        color: var(--secondary);
        font-weight: 600;

        transition: inherit;

        -webkit-appearance: none;
        
        &:hover {
            border-color: #cdcdcd;
        }

        &:hover + label,
        &:not(:placeholder-shown) + label,
        &:focus + label {
            opacity: 1;
            transform: translateY(0);
        }

        &::-webkit-input-placeholder {
            @include placeholder();
        }

        &:-moz-placeholder {
            @include placeholder();
        }

        &::placeholder {
            @include placeholder();
        }

        &:-ms-input-placeholder {
            @include placeholder();
        }

        @include md {
            @include paddingY(17px);
        }

        &:disabled {
            color: gray;
        }
    }

    &.-search {
        input {
            padding-right: 45px;
        }

        &:after {
            content: "";
            position: absolute;
            right: 17px;
            top: 50%;
            transform: translateY(-50%);
            background: url("../assets/search.svg");
            background-repeat: no-repeat;
            @include size(20px);
        }
    }

    svg {
        opacity: 0;

        width: 20px;
        height: 20px;

        position: absolute;
        right: 20px;
        top: calc(50% - 10px);

        transition: inherit;
    }

    &-error {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        bottom: 100%;
        right: 0;

        text-align: right;
        @include set-font(13px, 25px);
        color: #eb9191;

        padding: 0 5px 5px 0;

        transition: inherit;
        transform: translateY(2px);
    }

    &.-error {
        input,
        textarea {
            border-color: #eb9191;
            & + label {
                opacity: 1;
                transform: translateY(0);
            }
        }
        #{$root}-error {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
        }

        svg {
            opacity: 1;
        }
    }

    @include md {
        input,
        textarea {
            @include set-font(16px, 25px);
        }
    }
}

.a4-form-error {
    display: none;
    margin-top: 40px;
    text-align: center;
    color: #eb9191;
    font-size: 15px !important;
}
