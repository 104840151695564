@import 'variables.scss';
@import 'functions.scss';
@import 'mixins.scss';
@import 'fonts.scss';
@import 'placeholders.scss';
@import 'button.scss';

.a4-navbar {
    $root: &;
    padding-top: 30px;
    padding-bottom: 20px;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    transition: transform 0.7s, padding-top 0.7s;
    z-index: 997;

    background: #fff;

    &-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 18px;

        position: relative;
        z-index: 999;

        @include sm {
            padding: 0 30px;
        }

        @include sm {
            padding: 0 40px;
        }
    }

    &-navigation {
        display: flex;
        align-items: center;
    }

    &-brand {
        .-logo img {
            height: 35px;
            max-width: 204px;
        }

        @include sm {
            .-logo img {
                height: 47px;
                max-width: 287px;
            }
        }

        @include md {
            .-logo img {
                max-width: 200px;
            }
        }

        @include lg {
            .-logo img {
                height: 52px;
                max-width: 263px;
            }
        }

        @include xl {
            .-logo img {
                height: 62px;
                max-width: 303px;
            }
        }
    }

    &-menu {
        display: none;
        &-item {
            @include set-font(14px, 19px, -0.02em);
            font-weight: bold;
            color: var(--secondary);
            transition: color 0.2s ease-in-out;

            & + & {
                margin-left: 30px;
            }

            &:hover {
                color: var(--primary);
            }

            @include xl {
                @include set-font(16px, 22px, -0.02em);
            }
        }

        @include lg {
            display: flex;
        }
    }

    &-account {
        display: none;
        margin-right: 22px;

        &-base-list {
            display: none;

            &-item {
                @include set-font(14px, 19px, -0.02em);
                font-weight: bold;
                //color: color(var(--primary), 0.7);
                color: rgba(var(--secondaryR), var(--secondaryG), var(--secondaryB), 0.3);
                transition: color 0.2s ease-in-out;

                & + & {
                    margin-left: 30px;
                }

                &:hover {
                    color: var(--primary);
                }

                &.-active {
                    color: var(--primary) !important;
                }

                @include xl {
                    @include set-font(16px, 22px, -0.02em);
                }
            }

            @include lg {
                display: flex;
            }
        }

        &-icon-list {
            display: flex;
            &-item {
                height: 20px;
                a {
                    &.-active {
                        color: var(--primary);
                    }
                    &:not(.-active) {
                        opacity: 0.3;
                    }
                }
            }

            svg {
                width: 20px;
                height: 20px;
                fill: none;

                &.-login {
                    margin-right: 23px;
                }
            }

            @include lg {
                display: none;
            }
        }

        @include md {
            display: block;
        }

        @include lg {
            margin-right: 30px;
        }

        @include xl {
            margin-right: 40px;
        }
    }

    &-try_free {
        display: none;

        @include lg {
            display: block;
        }
    }

    &-control {
        display: flex;
        justify-content: center;
        //flex-direction: column;
        margin-right: 20px;

        &-item {
            text-align: center;
        }

        &-item + &-item {
            //margin-top: 16px;
            margin-left: 16px;
        }

        .a4-btn_store {
            padding: 9px 12px;
        }

        .a4-btn_store-icon {
            font-size: 18px;
            line-height: 0;
            min-width: 18px;
        }

        .a4-btn_store-text {
            font-size: 7px;
        }

        .a4-btn_store-text b {
            font-size: 11px;
        }

        @include sm {
            margin-right: 30px;
            flex-direction: row;
            justify-content: flex-start;

            button {
                margin: unset;
            }

            &-item + &-item {
                margin-top: 0;
                margin-left: 14px;
            }

            .a4-btn_store {
                padding: 12px 20px;
            }

            .a4-btn_store-icon {
                font-size: 23px;
                line-height: 0;
                min-width: 23px;
            }

            .a4-btn_store-text {
                font-size: 9px;
            }

            .a4-btn_store-text b {
                font-size: 14px;
            }
        }

        @include lg {
            display: none;
        }
    }

    &-menu-toggle {
        display: flex;
        cursor: pointer;

        svg.-burger {
            width: 34px;
            height: 20px;
        }

        @include lg {
            display: none;
        }
    }

    &-nav {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 998;

        overflow: hidden;
        max-height: 0;
        transition: max-height 0s 0.8s;

        &-filler {
            @extend %a4-filler;
            background: #fff;
            transform: scaleY(0);
            transform-origin: top center;

            transition: transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
            transition-delay: 0.2s;
        }

        &-content {
            height: 100%;
            opacity: 0;
            transition: opacity 0.35s;
            overflow: auto;

            padding-top: 90px;
        }

        &-footer {
            &-inner {
                display: flex;
                justify-content: center;

                position: relative;
                z-index: 10;
            }
        }

        &-list {
            margin-bottom: 36px;

            &-item {
                @include set-font(16px, 28px, -0.01em);
                color: var(--secondary);
                font-weight: bold;
                text-align: center;

                &:not(:first-child) {
                    margin-top: 14px;
                }

                &#nav-login,
                &#nav-reg {
                    color: rgba(var(--secondaryR), var(--secondaryG), var(--secondaryB), 0.3);
                    &.-active {
                        color: var(--primary);
                    }
                }

                @include sm {
                    // @include set-font(36px, 62px, -0.02em);
                    @include set-font(24px, 42px, -0.02em);
                }
            }
        }

        // @include sm {
        //     &-content {
        //         padding-top: 170px;
        //     }

        //     &-list-item {
        //         &:not(:first-child) {
        //             margin-top: 48px;
        //         }
        //     }
        // }

        @include lg {
            display: none;
        }
    }

    &.-hide {
        transform: translateY(-100%);
    }

    &:not(.-hide).-fixed {
        padding-top: 20px;
    }

    &.-open {
        #{$root} {
            &-menu-toggle button {
                @extend .-active;
            }

            &-nav {
                max-height: 100%;
                transition-delay: 0s;

                &-content {
                    opacity: 1;
                }

                &-filler {
                    transform: scaleY(1);
                    transition-delay: 0s;
                }

                &-list-item {
                    animation: ul-navbar-menu-nav-show 0.6s
                        cubic-bezier(0.19, 1, 0.22, 1) backwards;

                    @for $i from 1 through 10 {
                        &:nth-child(#{$i}) {
                            animation-delay: ($i * 0.07s);
                        }
                    }
                }

                &-footer-inner button {
                    animation: ul-navbar-menu-nav-show 0.6s
                        cubic-bezier(0.19, 1, 0.22, 1) backwards;
                    animation-delay: 0.4s;

                    @include sm {
                        font-size: 22px;
                        padding: 18px 56px;
                        border-radius: 50px;
                    }
                }
            }
        }
    }

    &.-primary-light {
        background: var(--primary-light);
    }

    li.-active {
        color: var(--primary);
    }

    @include md {
        padding-top: 47px;
    }
    @include lg {
        padding-top: 40px;
    }
    @include xl {
        padding-top: 35px;
    }
}

@keyframes ul-navbar-menu-nav-show {
    0% {
        opacity: 0;
        transform: translateY(112%);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}