@mixin fill($backround) {
    &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: -2;
        background: $backround;
    }
}
@mixin store-lg() {
    @include paddingY(12px);
    @include paddingX(20px);

    .a4-btn_store-icon {
        font-size: 32px;
        line-height: 0;
        min-width: 32px;
    }

    .a4-btn_store-text {
        font-size: 12px;

        b {
            font-size: 18px;
        }
    }
}

@mixin store-btn($fixed: false) {
    $root: &;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    color: #fff;
    transition: color 0.4s;

    border: 1px solid var(--secondary);
    border-radius: 10px;

    background: var(--secondary);
    @include md {
        &.-bubble {
            @include bubble(#fff, 10px);
        }
        &:not(.-bordered):hover {
            color: var(--secondary);
            border-color: var(--secondary);
    
            #{$root}-text,
            #{$root}-text b {
                color: rgba(var(--secondaryR), var(--secondaryG), var(--secondaryB), 0.7);
    
                b {
                    color: var(--secondary);
                }
            }
        }
    
        &.-bordered:hover {
            color: var(--secondary);
    
            #{$root}-text,
            #{$root}-text b {
                color: rgba(var(--secondaryR), var(--secondaryG), var(--secondaryB), 0.7);
    
                b {
                    color: var(--secondary);
                }
            }
        }
    }

    @if $fixed != true {
        @include paddingY(14px);
        @include paddingX(18px);

        &-icon {
            margin-right: 10px;
            font-size: 29px;
            line-height: 0;
            min-width: 29px;
        }

        &-text {
            font-size: 10px;
            line-height: 120%;
            letter-spacing: -0.01em;
            color: rgba(#fff, 0.7);
            transition: color 0.4s;
            font-weight: 500;
            text-align: left;

            b {
                display: block;
                @include set-font(16px, 1.2, -0.01em);
                color: #fff;
                transition: color 0.4s;
                font-weight: 600;
            }
        }

        @include sm {
            @include paddingY(12px);
            @include paddingX(20px);

            &-icon {
                font-size: 23px;
                line-height: 0;
                min-width: 23px;
            }

            &-text {
                font-size: 9px;

                b {
                    font-size: 14px;
                }
            }
        }

        @include lg {
            @include store-lg();
        }
    } @else {
        @include store-lg();
    }

    

    &.-bordered {
        border-color: rgba(255, 255, 255, 0.2);
    }
}

@mixin bubble($background, $border-radius: 30px) {
    @include md {
        &:hover > span.a4-btn-bubble > span {
            transform: translate(-50%, -50%) scale(6);
        }

        span.a4-btn-bubble {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            border-radius: $border-radius;
            pointer-events: none;

            overflow: hidden;

            span {
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                width: 100%;

                z-index: -1;

                padding-top: 100%;

                background: $background;
                border-radius: 50%;

                transform: translate(-50%, -50%) scale(0);
                transition: transform 0.35s ease-in-out;
            }
        }
    }
}

.a4-btn {
    $root: &;
    display: inline-block;
    position: relative;
    z-index: 1;

    color: inherit;
    white-space: nowrap;

    box-sizing: border-box;
    background: #fff;
    border: none;
    border-radius: 0;
    outline: none !important;
    overflow: hidden;

    transition: color 0.4s;

    margin: 0;

    &_store {
        @include store-btn();
    }

    &_store.-fixed {
        @include store-btn($fixed: true);
    }

    &-burger {
        $hover-speed: 0.4s;
        $width: 34px;
        $height: 20px;
        $weight: 3px;
        $padding: 20px;

        --opened-width: #{$width};
        --opened-height: #{$height};

        position: relative;
        display: inline-block;
        width: $width;
        height: $height;
        cursor: pointer;
        padding: 0;
        overflow: visible;

        background-color: transparent;

        .backdrop {
            position: absolute;
            top: 0;
            left: 0;
            width: $width + $padding * 2;
            height: $height + $padding * 2;
            margin: #{-$padding} 0 0 #{-$padding};
        }

        .burger {
            position: relative;
            display: inline-block;
            width: 100%;
            height: 100%;
            transition: transform $hover-speed ease-in-out;
            transition-timing-function: cubic-bezier(0.61, 0.04, 0.17, 1.32);
            z-index: 9;

            &:after,
            &:before {
                content: "";
                transform: translateX(-50%);
                transform-origin: 50% 50%;
                transition: all $hover-speed ease-in-out;
            }

            .middle,
            &:after,
            &:before {
                position: absolute;
                width: $width;
                width: var(--opened-width);
                height: $weight;
                background: var(--secondary);
                border-radius: 10px;
            }

            .middle,
            &:before {
                left: 50%;
            }

            &:after {
                bottom: 0;
                transform: scaleX(0.5) translateX(-50%);
            }

            &:before {
                top: 0;
            }

            .middle {
                top: 50%;
                display: inline-block;
                transform: translate(-50%, -50%);
                transition: background-color #{$hover-speed - 0.1s} ease-in-out;
            }
        }

        &.-active {
            .burger {
                transform: rotateZ(360deg);

                .middle,
                &:after,
                &:before {
                    background: currentColor;
                }
                .middle {
                    background-color: transparent;
                }
                &:after {
                    bottom: calc(50% - #{$weight});
                    transform: translate(-50%, -50%) rotate(135deg);
                    width: 100%;
                    right: unset;
                }
                &:before {
                    top: 50%;
                    transform: translate(-50%, -50%) rotate(45deg);
                }
            }
        }
    }

    &[disabled] {
        cursor: default;
        box-shadow: none;
    }

    &.-secondary {
        @include set-font(18px, 28px);

        padding: 11px 36px;

        border-radius: 30px;
        border: 1px solid var(--secondary);

        color: #fff;
        font-style: normal;
        font-weight: bold;

        background: var(--secondary);
        &[disabled] {
            background: #8c94a4;
            color: rgba(255, 255, 255, 0.5);
        }
        @include md {
            &.-bubble {
                @include bubble(#fff, 30px);

                &:hover {
                    color: var(--secondary);
                    border-color: var(--secondary);
                }
            }
        }
    }

    &.-primary {
        @include paddingY(10px);
        @include paddingX(30px);

        border: 1px solid var(--primary);
        border-radius: 30px;

        @include set-font(14px, 21px);
        color: #fff;
        font-weight: 600;
        font-style: normal;

        background: var(--primary);

        &[disabled] {
            background: #a5eae3;
            color: rgba(255, 255, 255, 0.5);
        }
        @include md {
            &.-bubble:hover {
                border-color: var(--primary);
                color: var(--primary);
            }
            &.-bubble {
                @include bubble(var(--primary-light), 30px);
            }
        }
        &:not(.-fixed) {
            @include sm {
                @include set-font(16px, 22px);
                @include paddingY(14px);
            }

            @include md {
                @include set-font(18px, 25px);
                @include paddingY(18px);
            }

            @include xl {
                @include set-font(20px, 27px);
            }
        }

        &.-loading {
            &[disabled] {
                background: var(--primary);
                color: var(--primary);
            }
            .a4-btn-bubble {
                display: none;
            }
            &::after {
                content: "";
                position: absolute;
                left: calc(50% - 20px);
                top: calc(50% - 20px);
                width: 40px;
                height: 40px;
                background: url("../assets/btn-spinner.svg") 100% 100% no-repeat;
                background-size: 40px 40px;
                background-position: center;
                animation: rotation 2s infinite linear;
            }
        }
    }
}
