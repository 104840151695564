@import 'variables.scss';
@import 'functions.scss';
@import 'mixins.scss';
@import 'fonts.scss';
@import 'placeholders.scss';

.a4-create-account {
    &-container {
        @include container($sm: 604px, $md: 814px, $lg: 984px, $xl: 1154px);
        @include paddingY(80px);
        @include sm {
            @include paddingY(100px);
        }

        @include md {
            @include paddingY(169px);
        }

        @include xl {
            @include paddingY(221px);
        }
    }

    &-content {
        @include sm {
            display: flex;
            justify-content: space-between;
        }
    }

    @include sm {
        &-group {
            max-width: 284px;
        }
    }

    &-info {
        margin-bottom: 40px;

        @include sm {
            margin-bottom: 0;
        }
        @include lg {
            margin-left: 86px;
        }
        @include xl {
            margin-left: 57px;
        }
    }

    &-title {
        @extend .text-lg;
        font-weight: 800;
    }

    &-description {
        @extend %description;

        @include sm {
            width: 284px;
        }
        @include md {
            width: 379px;
        }
        @include xl {
            width: 460px;
        }
    }

    &-group {
        &:not(:first-child) {
            margin-top: 30px;
        }
        @include sm {
            max-width: 100%;
        }
    }

    &-control {
        margin-top: 40px;
        display: flex;
        align-items: center;

        button[type="submit"] {
            flex-shrink: 0;
            margin-right: 13px;
            font-size: 14px !important;
        }

        a.a4-already-have-acc {
            @include set-font(14px, 21px);
            font-weight: bold;
            color: var(--secondary);
        }

        @include sm {
            flex-direction: column;
            align-items: center;

            button[type="submit"] {
                margin-right: 16px;
            }

            a.a4-already-have-acc {
                margin-top: 8px;
            }
        }
        @include md {
            flex-direction: column;
            align-items: center;
            margin-top: 30px;

            button[type="submit"] {
                margin-right: 0;
                margin-bottom: 20px;
                font-size: 18px !important;
            }

            a.a4-already-have-acc {
                @include set-font(16px, 26px);
            }
        }
        @include lg {
            flex-direction: row;
            align-items: center;
            justify-content: space-around;

            button[type="submit"] {
                margin-right: 6px;
                margin-bottom: 0px;
            }
        }
        @include xl {
            button[type="submit"] {
                @include set-font(18px, 28px);
                margin-right: 24px;
                margin-bottom: 0px;
            }

            a.a4-already-have-acc {
                @include set-font(18px, 28px);
            }
        }
    }

    &-terms {
        margin-top: 40px;

        @include set-font(13px, 24px);
        color: rgba(24, 40, 72, 0.4);

        @include md {
            @include set-font(14px, 24px);
            margin-top: 20px;
        }
        @include lg {
            margin-top: 60px;
        }
        @include xl {
            @include set-font(16px, 28px);
        }
    }

    &-form a {
        color: var(--secondary);
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    @include sm {
        &-form {
            width: 284px;
        }
    }
    @include md {
        &-form {
            width: 304px;
        }
    }
    @include lg {
        &-form {
            width: 389px;
        }
    }
    @include xl {
        &-form {
            width: 437px;
        }
    }
}
