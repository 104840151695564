@import 'variables.scss';
@import 'functions.scss';
@import 'mixins.scss';
@import 'fonts.scss';
@import 'placeholders.scss';

.a4-calendar {

    &-container {
        position: relative;
        @include paddingY(30px, 50px);

        &::after {
            content: "";
            @extend %a4-filler;
            background: #ffffff;
            
            @include sm {
                border-radius: 30px;
            }
        }
        @include shadow();

        @include sm {
            display: flex;
        }

        @include md {
            display: block;
            width: 507px;
            @include paddingY(50px);
        }

        @include xl {
            display: flex;
            width: 704px;
        }
    }

    &-content {
        @include paddingX(20px);

        @include sm {
            width: 604px;
            @include paddingX(40px, 10px);
        }

        @include md {
            width: auto;
            @include paddingX(45px);
        }

        @include xl {
            @include paddingX(45px, 20px);
        }
    }

    &-header {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;

        &-current-month {
            @include set-font(18px, 34px);
            font-weight: 700;

            @include md {
                @include set-font(24px, 34px);
            }
        }

        &-controls {
            display: flex;
            align-items: center;

            @include md {
                padding-right: 10px;
            }

            @include xl {
                padding-right: 0px;
            }

            &-button {
                height: 38px;
                width: 38px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                &.-previous {
                    margin-right: 25px;
                }

                svg {
                    height: 12px;
                    width: 12px;
                }
            }
        }
    }


    &-days {
        margin-top: 30px;

        @include sm {
            width: 402px;
        }

        &-row {
            display: flex;
            justify-content: space-between;
            margin-top: 10px;
        }

        &-cell {
            height: 38px;
            width: 38px;
            border-radius: 50%;

            display: flex;
            justify-content: center;
            align-items: center;

            @include sm {
                height: 52px;
                width: 52px;
            }

            user-select: none;

            &.-unselected {
                cursor: pointer;
                background-color: var(--primary-light);
                color: var(--primary);

                & .a4-calendar-days-day {
                    font-weight: 500;
                }
            }

            &.-selected {
                cursor: pointer;
                background-color: var(--primary);
                color: white;

                & .a4-calendar-days-day {
                    font-weight: 700;
                }
            }

            &.-disabled {
                opacity: 0.10;
            }
        }

        &-header {
            @include set-font(13px, 24px);
            font-weight: 400;
            text-align: center;

            @include sm {
                @include set-font(18px, 32px);
            }
        }

        &-day {
            @include set-font(14px, 24px);
            font-weight: 400;
            text-align: center;

            @include sm {
                @include set-font(18px, 32px);
            }
        }
    }

    &-info {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 20px;

        @include paddingX(20px);

        @include sm {
            margin-top: 0px;
            @include paddingX(15px, 20px);
            @include paddingY(10px, 0px);
        }

        @include md {
            margin-top: 20px;
            @include paddingX(45px);
            @include paddingY(0px, 0px);
        }

        @include xl {
            margin-top: 0px;
            @include paddingX(20px);
            @include paddingY(5px, 0px);
        }

        &-item {
            margin-top: 30px;
            padding-right: 30px;

            @include sm {
                margin-top: 0px;
                padding-right: 0px;
            }

            @include md {
                margin-top: 30px;
                padding-right: 30px;
            }

            @include xl {
                margin-top: 0px;
                padding-right: 0px;
            }

            &.-buy {
                width: 100%;
                margin-top: 50px;
                display: flex;
                justify-content: center;
                padding-right: 0px;

                button {
                    height: 55px;
                    width: 100%;
                    max-width: 300px;
                }

                @include sm {
                    margin-top: 80px;
                }

                @include md {
                    margin-top: 50px;

                    button {
                        height: auto;
                    }
                }

                @include xl {
                    margin-top: 50px;

                    button {
                        height: 60px;
                    }
                }
            }
        }

        &-label {
            @include set-font(13px, 18px);
            font-weight: 400;
            color: var(--primary);

            @include md {
                @include set-font(16px, 32px);
            }
        }

        &-text {
            @include set-font(14px, 26px);
            font-weight: 700;
            color: var(--secondary);
            margin-top: 10px;

            @include md {
                @include set-font(18px, 28px);
            }

            svg {
                width: 16px;
                height: 16px;
                margin-right: 8px;
            }

            &.-duration {
                display: flex;
                align-items: center;
            }
        }
    }
}