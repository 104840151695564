@import 'variables.scss';
@import 'functions.scss';
@import 'mixins.scss';
@import 'fonts.scss';
@import 'placeholders.scss';
@import 'button.scss';

.a4-error {
    &-content {
        margin-top: 85px;

        @include sm {
            margin-top: 110px;
        }

        @include md {
            margin-top: 137px;
        }

        @include xl {
            margin-top: 150px;
        }
    }
}

.a4-error-exposition {
    $root: &;

    &-container {
        @include container($sm: 728px, $md: 984px, $xl: 1440px);
        @include paddingX(18px);
    }

    &-content {
        @include marginY(80px);
        display: flex;
        align-items: center;
        flex-direction: column;

        &.-shell {
            border-radius: 30px;
            background: var(--primary-light);
        }

        @include sm {
            padding-left: 62px;
            @include marginY(100px, 112px);

            flex-direction: row;
            justify-content: space-between;

            &.-shell {
                border-radius: 40px;
            }
        }
        @include md {
            padding-left: 98px;
            @include marginY(134px);
        }
        @include xl {
            padding-left: 200px;
            @include marginY(173px, 160px);
        }
    }

    &-illustration {
        text-align: center;
        padding-top: 30px;

        @include sm {
            padding-top: 0px;
        }

        img {
            display: block;
            width: 244px;
            height: 207px;

            @include sm {
                width: 397px;
                height: 337px;
            }
            @include md {
                width: 484px;
                height: 410px;
            }
            @include lg {
                width: 484px;
                height: 410px;
            }
            @include xl {
                width: 638px;
                height: 541px;
            }
        }
    }

    &-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        margin-bottom: 40px;
        z-index: 1;

        @include sm {
            display: block;
            text-align: left;

            max-width: 284px;
            margin-bottom: 0;
        }
        @include md {
            max-width: 373px;
        }
        @include xl {
            max-width: 460px;
        }
    }

    &.-highlighted {
        #{$root} {
            &-content {
                @extend .-shell;
                @include paddingY(30px);
                @include sm {
                    padding-left: 0;
                }
            }

            &-info {
                text-align: center;
                @include paddingX(15px);
                margin-bottom: 0;
                @include sm {
                    margin-bottom: 45px;
                }
            }

            &-code {
                @include set-font(104px, 124px, -0.01em);
                color: var(--secondary);
                font-weight: 900;
            
                @include sm {
                    @include set-font(144px, 164px, -0.02em);
                }
                @include md {
                    @include set-font(144px, 164px, -0.02em);
                }
                @include lg {
                    @include set-font(144px, 164px, -0.02em);
                }
                @include xl {
                    @include set-font(196px, 208px, -0.02em);
                }
            }
            &-title {
                @include set-font(26px, 34px, -0.01em);
                color: var(--secondary);
                font-weight: 800;
            
                @include sm {
                    @include set-font(44px, 58px, -0.02em);
                }
                @include md {
                    @include set-font(44px, 58px, -0.02em);
                }
                @include lg {
                    @include set-font(44px, 58px, -0.02em);
                }
                @include xl {
                    @include set-font(60px, 82px, -0.02em);
                }
            }
            &-control {
                margin-top: 40px;
                @include sm {
                    margin-top: 40px;
                }
            }
        }

        @include sm {
            #{$root} {
                &-info {
                    max-width: 339px;
                    @include paddingX(0px);
                }

                &-content {
                    flex-direction: column;
                    @include paddingY(80px, 50px);
                    margin-bottom: 100px;
                }
            }
        }

        @include md {
            #{$root} {
                &-content {
                    flex-direction: row;
                    @include paddingX(98px, 20px);
                }

                &-info {
                    max-width: 391px;
                    text-align: left;
                }
            }
        }

        @include xl {
            #{$root} {
                &-content {
                    @include paddingX(200px, 58px);
                }

                &-info {
                    max-width: 449px;
                }
            }
        }
    }

    &-control {
        margin-top: 40px;

        @include sm {
            margin-top: 50px;
        }
        @include xl {
            margin-top: 60px;
        }
    }

    &-description {
        margin-top: 40px;

        @extend .text-sm;
        font-weight: normal;
        color: var(--secondary);

        @include lg {
            margin-top: 60px;
        }
        @include xl {
            margin-top: 60px;
        }
    }
}