/* colors */
:root {
  --primary: #4AD5C6;
  --primary-light: #f4fdfc;
  --secondary: #182848;
}

/* font */
$font-family: "Manrope", sans-serif;
$font-base: 16px;

// Added +15 for Scroll Bar Width (Note: +17 on Windows)
$screens: (
  xs: 390px, // 390px (old = 375px) 
  sm: 783px, // 783px (old = 768px)
  md: 1039px, // 1039px (old = 1024px)
  lg: 1215px, // 1215px (old = 1200px)
  ml: 1415px, // 1415px (old = 1400px)
  xl: 1615px, // 1615px (old = 1600px)
);
