.a4-spinner {
    width: 60px;
    height: 60px;
    // background: url("./assets/spinner.svg") 100% 100% no-repeat;
    // background-size: 40px 40px;
    // background-position: center;

    -webkit-mask-image: url("./assets/spinner.svg");
    mask-image: url("./assets/spinner.svg");
    mask-size: 60px 60px;
    mask-position: center;
    background-color: var(--spinner-color);

    animation: rotation 2s infinite linear;
}
@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}