@import 'variables.scss';
@import 'functions.scss';
@import 'mixins.scss';
@import 'fonts.scss';
@import 'placeholders.scss';

.a4-single-course {
    $root: &;

    &-container {
        @include container($sm: 728px, $md: 984px, $xl: 1440px);
        @include paddingX(18px);
    }

    &-column {
        @include marginY(80px);
        border-radius: 30px;
        background: var(--primary-light);

        padding-left: 10px;
        padding-right: 10px;

        display: flex;
        flex-direction: column;
        justify-content: center;

        @include sm {
            padding-left: 37px;
            padding-right: 0px;
            @include marginY(100px, 112px);
            border-radius: 40px;
        }
        @include md {
            padding-left: 98px;
            @include marginY(134px);
        }
        @include xl {
            padding-left: 200px;
            @include marginY(173px, 160px);
        }
    }

    &-content {
        display: flex;
        align-items: center;
        flex-direction: column;

        @include sm {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    &-header {
        text-align: center;
        @extend .text-xl;
        font-weight: bold;
    }

    &-illustration {
        text-align: center;
        margin-top: -50px;

        order: 1;
    }

    &-details {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        margin-bottom: 40px;
        z-index: 1;

        order: 2;

        @include sm {
            order: 0;
            display: block;
            text-align: left;

            max-width: 284px;
            margin-bottom: 0;
        }
        @include md {

            max-width: 373px;
        }
        @include xl {
            max-width: 460px;
        }
    }

    &-title {
        @extend .text-lg;
        font-weight: 800;
        color: var(--secondary);
    }

    &.-highlighted {
        #{$root} {
            &-column {
                border-radius: 30px;
                background: var(--primary-light);
                @include paddingY(50px, 30px);
            }

            &-content {
                flex-direction: column;
            }

            &-details {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
        
                margin-bottom: 40px;
                z-index: 1;
        
                order: 2;
        
                @include sm {
                    order: 0;
                    display: block;
                    text-align: left;
        
                    max-width: 284px;
                    margin-bottom: 0;
                }
                @include md {
        
                    max-width: 373px;
                }
                @include xl {
                    max-width: 460px;
                }
            }

            &-info {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 20px;

                @include sm {
                    align-items: flex-start;
                }

                @include md {
                    flex-direction: row;
                    align-items: center;
                }

                @include xl {
                    margin-top: 40px;
                }

                &-item {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    &.-right {
                        padding-top: 10px;

                        @include md {
                            padding-top: 0px;
                            padding-left: 40px;
                        }
                    }
                }

                &-label {
                    @include set-font(14px, 24px);
                    font-weight: 500;
                    color: var(--primary);

                    @include md {
                        @include set-font(16px, 26px);
                    }

                    @include xl {
                        @include set-font(18px, 28px);
                    }
                }

                span {
                    @include set-font(16px, 24px);
                    font-weight: 600;
                    color: var(--secondary);
                    padding-left: 10px;

                    @include md {
                        @include set-font(18px, 26px);
                    }

                    @include xl {
                        @include set-font(20px, 28px);
                    }
                }
            }

            &-title {
                @extend .text-lg;
                font-weight: 800;
            }
            &-control {
                margin-top: 20px;
                @include sm {
                    margin-top: 40px;
                }
            }
            &-illustration {
                position: relative;
                margin-top: 0px;

                &-background {
                    position: absolute !important;
                    bottom: 0;
                    right: 0;
                    height: 75%;
                }

                img {
                    position: relative;
                    display: block;
                    width: 204px;
                    &:last-of-type {
                        height: 200px;
                    }
                }
            }
        }

        @include sm {
            #{$root} {
                &-details {
                    max-width: 339px;
                    @include paddingX(0px);
                }

                &-column {
                    @include paddingY(100px, 70px);
                    padding-right: 30px;
                    margin-bottom: 100px;
                }

                &-content {
                    flex-direction: row;
                    padding-top: 20px;
                }

                &-illustration img {
                    margin-right: 10px;
                    margin-bottom: 100px;
                    margin-top: 40px;

                    width: 307px;
                    &:last-of-type {
                        height: 301px;
                    }
                }
            }
        }

        @include md {
            #{$root} {
                &-column {
                    @include paddingY(100px, 70px);
                    @include paddingX(98px, 98px);
                }

                &-details {
                    max-width: 391px;
                    text-align: left;
                }

                &-illustration img {
                    margin-right: 0px;
                    
                    width: 378px;
                    &:last-of-type {
                        height: 371px;
                    }
                }
            }
        }

        @include xl {
            #{$root} {
                &-column {
                    @include paddingY(130px, 60px);
                    @include paddingX(200px, 200px);
                }

                &-content {
                    padding-top: 30px;
                }

                &-details {
                    max-width: 500px;
                }

                &-illustration img {                    
                    width: 458px;
                    &:last-of-type {
                        height: 450px;
                    }
                }
            }
        }
    }

    &-control {
        margin-top: 40px;

        @include sm {
            margin-top: 50px;
        }
        @include xl {
            margin-top: 60px;
        }
    }

    &-description {
        @extend %description;
    }
}
