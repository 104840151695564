@import 'variables.scss';
@import 'functions.scss';
@import 'mixins.scss';
@import 'fonts.scss';
@import 'placeholders.scss';

.a4-special-offers {
    background: var(--primary-light);

    &-container {
        @include container($sm: 604px, $md: 794px, $xl: 1040px);
    }

    &-content {
        @include paddingY(80px, 92px);

        @include sm {
            display: flex;
            justify-content: flex-end;
            @include paddingY(100px);
        }
        @include md {
            @include paddingY(150px, 130px);
        }
        @include xl {
            justify-content: center;
            @include paddingY(140px, 140px);
        }
    }

    &-info {
        margin-bottom: 60px;
    }

    &-title {
        h2 {
            @extend .text-lg;
            font-weight: 800;
        }
    }

    &-description {
        @extend %description;
    }

    &-form {
        position: relative;
        @include paddingY(50px, 33px);
        max-width: 100vw;
        margin: 0 calc(-50vw + 50%);
        @include paddingX(20px);
        .a4-input {
            text-align: center;
        }
        &::after {
            content: "";
            @extend %a4-filler;
            background: #fff;
            z-index: 1;
        }
        input {
            width: 100%;
            @include sm {
                max-width: 284px;
            }
        }

        &-control {
            display: flex;
            justify-content: center;
            margin-top: 40px;

            @include lg {
                justify-content: flex-start;
            }
        }

        &-section {
            z-index: 2;
            position: relative;
            &.-success {
                display: flex;
                flex-flow: column;
                justify-content: center;

                transition: opacity 0.4s;
                opacity: 0;
                touch-action: none;
                pointer-events: none;

                position: absolute;
                bottom: 0;
                top: 0;
                left: 0;
                right: 0;
                z-index: 2;

                text-align: center;
                background: #fff;
                border-radius: 30px;
                padding: 0 18px;

                figure {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    margin: 0 auto;
                    width: 70px;
                    height: 70px;
                    border-radius: 35px;

                    background-color: #f4fdfc;
                    color: var(--primary);

                    svg {
                        width: 22px;
                    }
                }
                h3 {
                    @include set-font(24px, 32px, -0.02em);
                    font-weight: 800;
                    margin: 30px 0 10px 0;
                }
                p {
                    @include set-font(14px, 26px, 0.02em);
                }
                &.-visible {
                    opacity: 1;
                    touch-action: unset;
                    pointer-events: unset;
                }
            }
        }
        @include sm {
            @include paddingX(0px);
            .a4-input {
                text-align: left;
                height: 55px;
            }
        }
    }

    &-sub-anytime {
        margin-top: 20px;
        text-align: center;

        p {
            font-size: 13px;
            line-height: 24px;
            color: rgba(var(--secondaryR), var(--secondaryG), var(--secondaryB), 0.4);
        }

        @include lg {
            text-align: left;
        }
    }

    @include sm {
        &-info {
            margin-top: 58px;
            width: 284px;
            margin-right: 36px;
        }

        &-form {
            width: 348px;

            @include paddingY(60px, 33px);
            @include paddingX(40px);
            max-width: 1000px;
            margin: 0;

            @include shadow();
            &::before {
                z-index: 0;
            }
            &::after {
                border-radius: 30px;
            }
            input {
                max-width: 100%;
            }
        }

        &-sub-anytime {
            margin-top: 47px;
        }
    }

    @include md {
        &-info {
            width: 379px;
            margin-top: 50px;
        }

        &-form {
            width: 474px;
            &-section.-success {
                h3 {
                    @include set-font(28px, 42px, -0.02em);
                }
                p {
                    @include set-font(16px, 30px, 0.02em);
                }
            }
        }

        &-control {
            margin-top: 30px;
        }

        &-sub-anytime {
            @include set-font(14px, 24px);
            margin-top: 57px;
        }
    }

    @include xl {
        &-info {
            width: 460px;
            margin-right: 97px;
        }
    }
}
