@import 'variables.scss';
@import 'functions.scss';
@import 'mixins.scss';
@import 'fonts.scss';
@import 'placeholders.scss';

.a4-client-reviews {
    overflow: hidden;
    &-info {
        margin-bottom: 60px;
    }

    &-title {
        h2 {
            @extend .text-lg;
            font-weight: 800;
        }
    }

    &-description {
        @extend %description;
    }

    &-list-container {
        max-width: 100vw;
    }

    &-list-control {
        position: absolute;
        left: 100%;
        bottom: calc(100% + 73px);

        background: var(--primary-light);
        @include size(60px);
        border-radius: 50%;

        cursor: pointer;

        svg {
            width: 20px;
            transition: transform 0.3s ease-in-out;
        }

        &:hover {
            svg {
                transform: scale(1.2);
            }
        }
    }

    &-list {
        display: inline-flex;

        &-item {
            width: 227px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 30px;
            @include paddingX(30px);
            @include paddingY(40px);

            color: var(--secondary);

            &:not(:first-child) {
                margin-left: 26px;
            }

            &-user {
                display: flex;
                margin-bottom: 20px;

                @include set-font(14px, 24px);

                &-avatar {
                    margin-right: 22px;

                    width: 50px;
                    height: 50px;
                    position: relative;
                    overflow: hidden;
                    border-radius: 50%;

                    flex-shrink: 0;

                    img {
                        display: inline;
                        margin: 0 auto;
                        height: 100%;
                        width: auto;
                    }

                    svg {
                        display: inline;
                        margin: 0 auto;
                        height: 100%;
                        width: auto;

                        color: var(--primary);
                    }
                }

                &-name {
                    font-weight: bold;
                }

                &-from {
                    opacity: 0.4;
                }
            }

            &-review {
                @include set-font(14px, 26px);
            }
        }

        &-control {
            display: none;

            @include sm {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .owl-stage-outer {
        overflow: unset;
    }
    @include sm {
        &-info {
            margin-bottom: 80px;
        }

        // .owl-stage-outer {
        //     padding-left: 82px;
        // }

        &-description {
            width: 347px;
        }

        &-list {
            &-item {
                width: 539px;
                @include paddingX(60px);
                @include paddingY(40px, 70px);
            }
        }
    }

    @include md {
        // .owl-stage-outer {
        //     padding-left: 115px;
        // }

        &-description {
            width: 379px;
        }

        &-list-control {
            @include size(90px);
            bottom: calc(100% + 85px);

            svg {
                width: 30px;
                height: 25px;
            }
        }

        &-list {
            &-item {
                width: 759px;
            }
        }
    }

    @include lg {
        // .owl-stage-outer {
        //     padding-left: 203px;
        // }
    }

    @include xl {
        &-description {
            width: 460px;
        }

        &-list-control {
            @include size(112px);
            bottom: calc(100% + 65px);
        }

        // .owl-stage-outer {
        //     padding-left: 173px;
        // }
    }
}
