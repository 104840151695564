@import 'variables.scss';
@import 'functions.scss';
@import 'mixins.scss';
@import 'fonts.scss';
@import 'placeholders.scss';
@import 'button.scss';
@import 'helpers.scss';
@import 'base.scss';
@import 'input.scss';
@import 'select.scss';
@import 'auth.scss';

.spinner-container {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 68px;

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include sm {
        margin-bottom: 80px;
    }
    @include md {
        margin-bottom: 120px;
    }
    @include xl {
        margin-bottom: 200px;
    }
}

.error-container {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 68px;

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include sm {
        margin-bottom: 80px;
    }
    @include md {
        margin-bottom: 120px;
    }
    @include xl {
        margin-bottom: 200px;
    }
}