@import 'variables.scss';
@import 'functions.scss';
@import 'mixins.scss';
@import 'fonts.scss';
@import 'placeholders.scss';

.a4-approvals {
    $root: &;

    &-container {
        @include container($sm: 728px, $md: 984px, $xl: 1440px);
    }

    &-main {
        &-content {
            display: flex;
            flex-direction: column;
            align-items: center;

            @include paddingY(100px, 27px);

            @include sm {
                @include paddingY(160px, 135px);
            }

            @include md {
                @include paddingY(140px, 81px);
            }

            @include lg {
                @include paddingY(140px, 71px);
            }

            @include xl {
                @include paddingY(200px, 60px);
            }
        }

        &-illustration {
            display: flex;
            justify-content: center;

            img {
                width: 240px;
                height: 179px;

                @include sm {
                    width: 530px;
                    height: 395px;
                }
                @include md {
                    width: 356px;
                    height: 265px;
                }
                @include xl {
                    width: 512px;
                    height: 381px;
                }
            }
        }

        &-title {
            margin-top: 20px;
            max-width: 470px;

            h1 {
                @extend .text-xl;
                font-weight: bold;
                text-align: center;
            }

            @include sm {
                max-width: 730px;
                margin-top: 60px;
            }

            @include md {
                margin-top: 10px;
            }

            @include xl {
                margin-top: 20px;
            }
        }
    }

    &-app-and-acc {
        &-content {
            @include paddingY(100px, 80px);

            @include sm {
                @include paddingY(100px, 100px);
            }
            @include md {
                @include paddingY(100px, 178px);
            }

            @include md {
                @include paddingY(100px, 140px);
            }
        }

        &-topic {
            & + & {
                margin-top: 140px;
            }

            &-title {
                h2 {
                    @extend .text-lg;
                    text-align: center;
                    font-weight: bold;
                }
            }

            @include sm {
                & + & {
                    margin-top: 90px;
                }
            }
        }

        #{$root}-list {
            margin-top: 50px;

            &-item {
                display: block;
                max-width: 400px;
                margin: auto;

                @include paddingY(40px);

                &:not(:first-child) {
                    margin-top: 26px;
                }

                &-image {
                    display: flex;
                    justify-content: center;
                    
                    img {
                        height: 87px;
                        width: 87px;
                    }
                }

                &-block {
                    margin-top: 30px;
                    &-title {
                        @include paddingX(10px);

                        h3 {
                            text-align: center;
                            @extend .text-md;
                            font-weight: bold;
                        }
                    }
                    &-description {
                        @extend %description;
                        margin-top: 10px;

                        p {
                            text-align: center;
                            color: rgba(var(--secondaryR), var(--secondaryG), var(--secondaryB), 0.5);
                        }
                    }
                    &-control {
                        text-align: center;
                        margin-top: 30px;
                        opacity: 1;
                        transition: opacity 0.7s;
                        a {
                            @include set-font(14px, 24px);
                            color: var(--primary);
                            font-weight: bold;
                        }

                        span:hover {
                            text-decoration: underline;
                        }
                    }
                }

                @include sm {
                    max-width: unset;
                    margin: unset;

                    display: flex;

                    padding: 70px;

                    &:not(:first-child) {
                        margin-top: 26px;
                    }

                    &-image {
                        align-self: flex-start;
                        padding-right: 30px;
                        min-width: 117px;
                    }

                    &-block {
                        margin-top: 0;

                        &-title {
                            @include paddingX(0);

                            h3 {
                                text-align: left;
                            }
                        }
                        &-description {
                            p {
                                text-align: left;
                            }
                        }
                        &-control {
                            text-align: left;
                            margin-top: 15px;

                            span {
                                @include set-font(16px, 24px);
                            }
                        }
                    }
                }
            }

            @include sm {
                margin-top: 60px;
            }
        }

        @include md {
            #{$root}-list {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;

                margin-top: 24px;
                margin-left: -36px;

                &-item {
                    max-width: 50%;
                    width: 473px;
                    position: relative;

                    @include paddingX(50px);
                    @include paddingY(60px);
                    margin-left: 36px;
                    margin-top: 50px;
                    &.-highlighted {
                        cursor: pointer;
                    }

                    &:not(:first-child) {
                        margin-top: 50px;
                    }

                    &-block {
                        &-control {
                            opacity: 0;
                            span {
                                line-height: 26px;
                            }
                        }
                    }

                    &.-highlighted:hover {
                        background: var(--primary-light);
                        border-radius: 30px;
                        .a4-approvals-list-item-block-control {
                            opacity: 1;
                        }
                    }
                }
            }
        }
        @include xl {
            #{$root}-list {
                margin-top: 34px;

                &-item {
                    width: 702px;
                    @include paddingX(70px);
                    @include paddingY(90px);

                    &-block {
                        &-control {
                            span {
                                @include set-font(16px, 28px);
                            }
                        }
                    }
                }
            }
        }
    }
}
