@import 'variables.scss';
@import 'functions.scss';
@import 'mixins.scss';
@import 'fonts.scss';
@import 'placeholders.scss';

.a4-complete-group {
    $root: &;

    margin: 100px 20px;

    &-content {
        position: relative;
        padding-top: 60px;

        @include sm {
            padding-top: 0px;
        }
    }

    &-title {
        h1 {
            @include set-font(30px, 38px, -0.01em);
            text-align: center;

            @include sm {
                @include set-font(48px, 62px, -0.02em);
                margin: auto;
            }
            @include sm {
                @include set-font(52px, 72px, -0.02em);
            }
            @include xl {
                @include set-font(64px, 85px, -0.02em);
            }
        }
    }

    &-back {
        position: absolute;
        top: 0;
        left: 0;

        a {
            display: flex;
            align-items: center;

            i {
                display: flex;
                margin-right: 30px;

                svg {
                    width: 6px;
                    height: 12px;
                }
            }

            span {
                @include set-font(14px, 19px);
                font-weight: normal;
                color: var(--secondary);
            }

            @include md {
                span {
                    @include set-font(16px, 22px);
                }
            }
        }

        @include lg {
            left: 90px;
        }
        @include lg {
            left: 60px;
        }
    }

    &-form {
        position: relative;
        &::after {
            content: "";
            @extend %a4-filler;
            background: #ffffff;
            border-radius: 30px;
        }
        &-group {
            position: relative;
            margin-top: 100px;

            @include sm {
                &:first-child {
                    margin-top: 0px;
                }
            }
        }

        .a4-select {
            margin-bottom: 40px;
            .select2-container .select2-selection {
                background: #F8F9FA;
                border: none;
                border-radius: 50px;
            }
            .select2-selection__rendered {
                @include set-font(14px, 28px);
            }
        }

        .a4-input {
            transition: margin-bottom 0.2s;
            margin-bottom: 34px;
            &:last-child {
                margin-bottom: 0px;
            }

            &.-disabled {
                appearance: none;
                margin-bottom: -56px;
            }
            @include md {
                &.-disabled {
                    margin-bottom: -61px;
                }
            }
        }
        &-selection {
            transition-property: color opacity;
            transition-duration: 0.2s;

            @include sm {
                height: 55px;
                padding: 14px 20px 13px 20px;

                @include set-font(14px, 28px);
                font-weight: 500;
                color: #ffffff;

                z-index: 2;

                -webkit-tap-highlight-color: transparent;

                &:not(.-active) {
                    opacity: 0.5;
                    color: var(--secondary);
                }
            }
            @include md {
                @include set-font(16px, 28px);
            }
        }

        .a4-btn {
            margin-top: 40px;
            width: 100%;
            height: 55px;
            font-weight: bold;
        }
        &-btn-group {
            position: relative;
            justify-content: space-between;
            @include sm {
                display: flex;
                align-items: center;
                margin-bottom: 70px;
                background-color: #F8F9FA;
                border-radius: 50px;
            }
        }

        &-small-input-group {
            @include sm {
                display: flex;
                align-items: baseline;
                .a4-input:nth-child(2) {
                    margin: 0 21px;
                }
            }
        }

        &-terms {
            margin-top: 24px;
            @include set-font(13px, 25px);
            color: rgba(24, 40, 72, 0.4);
            text-align: center;
            a {
                color: var(--primary);
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }

            @include sm {
                @include set-font(14px, 25px);
            }
        }

        @include sm {
            @include shadow();
            width: 604px;
            margin: 80px auto 0 auto;
            padding: 60px 100px;
        }
        @include md {
            width: 702px;
            margin-top: 100px;
            padding: 60px 123px;
        }

        &-paragraph {
            text-align: center;
        }
    }

    .a4-switcher {
        display: none;
        @include sm {
            display: block;
        }
    }

    @include sm {
        margin-top: 200px;
    }

    @include md {
        margin-top: 160px;
    }
}


.a4-switcher {
    position: absolute;
    left: 0;
    top: 0;
    height: 55px;
    background: var(--secondary);
    border-radius: 50px;
    z-index: 1;
}