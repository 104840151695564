@import 'variables.scss';
@import 'functions.scss';
@import 'mixins.scss';
@import 'fonts.scss';
@import 'placeholders.scss';
@import 'button.scss';

.a4-app-error-exposition {
    $root: &;

    &-container {
        @include container($sm: 728px, $md: 984px, $xl: 1440px);
        @include paddingX(18px);
    }

    &-content {
        @include marginY(50px);
        display: flex;
        align-items: center;
        flex-direction: column;

        &.-shell {
            border-radius: 30px;
            background: var(--primary-light);
        }

        @include sm {
            &.-shell {
                border-radius: 40px;
            }
        }
    }

    &-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        margin-bottom: 40px;
        z-index: 1;

        @include sm {
            display: block;

            max-width: 284px;
            margin-bottom: 0;
        }
        @include md {
            max-width: 373px;
        }
        @include xl {
            max-width: 460px;
        }
    }

    &.-highlighted {
        #{$root} {
            &-content {
                @extend .-shell;
                @include paddingY(30px);
                @include sm {
                    padding-left: 0;
                }
            }

            &-info {
                text-align: center;
                @include paddingX(45px);
                margin-bottom: 0;
                @include sm {
                    margin-top: 45px;
                    margin-bottom: 45px;
                }
            }

            &-code {
                @include set-font(104px, 124px, -0.01em);
                color: var(--secondary);
                font-weight: 900;
            
                @include sm {
                    @include set-font(144px, 164px, -0.02em);
                }
                @include md {
                    @include set-font(144px, 164px, -0.02em);
                }
                @include lg {
                    @include set-font(144px, 164px, -0.02em);
                }
                @include xl {
                    @include set-font(196px, 208px, -0.02em);
                }
            }
            &-title {
                @include set-font(26px, 34px, -0.01em);
                color: var(--secondary);
                font-weight: 800;
            
                @include sm {
                    @include set-font(44px, 58px, -0.02em);
                }
                @include md {
                    @include set-font(44px, 58px, -0.02em);
                }
                @include lg {
                    @include set-font(44px, 58px, -0.02em);
                }
                @include xl {
                    @include set-font(60px, 82px, -0.02em);
                }
            }
            &-control {
                margin-top: 40px;
                @include sm {
                    margin-top: 40px;
                }
            }
        }

        @include sm {
            #{$root} {
                &-info {
                    max-width: 339px;
                    @include paddingX(0px);
                }

                &-content {
                    flex-direction: column;
                    @include paddingY(50px);
                }
            }
        }

        @include md {
            #{$root} {
                &-info {
                    max-width: 391px;
                }
            }
        }

        @include xl {
            #{$root} {
                &-info {
                    max-width: 449px;
                }
            }
        }
    }

    &-description {
        margin-top: 40px;

        @extend .text-sm;
        font-weight: normal;
        color: var(--secondary);

        @include lg {
            margin-top: 40px;
        }
        @include xl {
            margin-top: 50px;
        }
    }
}