@import 'variables.scss';
@import 'functions.scss';
@import 'mixins.scss';
@import 'fonts.scss';
@import 'placeholders.scss';

.a4-four-plan {
    &-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-wrap: wrap;
        &-item {
            $item: &;
            position: relative;
            overflow: hidden;

            height: 261px;
            width: 284px;

            margin: 26px auto 0;
            @include paddingY(18px, 27px);

            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 30px;

            cursor: pointer;
            -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
            transition: background-color 0.2s ease-in-out;

            &-image {
                display: flex;
                justify-content: center;

                margin-bottom: 6px;

                img {
                    width: 153px;
                    height: 147px;
                }
            }

            &-title {
                font-size: 26px;
                line-height: 24px;
                font-weight: bold;
                text-align: center;
                color: var(--secondary);
                transition: color 0.2s ease-in-out;

                margin-bottom: 12px;
            }

            &-description {
                display: block;
                @include set-font(20px, 26px);
                color: rgba(var(--secondaryR), var(--secondaryG), var(--secondaryB), 0.6);
                text-align: center;
            }
        }

        @include sm {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    @include sm {
        &-list {
            &-item {
                $item: &;

                margin: unset;
                margin-bottom: 36px;
                width: 347px;
                &-description {
                    color: rgba(255, 255, 255, 0.6);
                }
                &:hover {
                    border-color: transparent;
                    background: var(--primary);

                    #{$item}-title {
                        color: white;
                    }

                    #{$item}-description {
                        display: block;
                    }
                }
            }
        }
    }

    @include md {
        &-list {
            &-item {
                width: 473px;
            }
        }
    }

    @include xl {
        &-list {
            &-item {
                &-image {
                    img {
                        width: 195px;
                        height: 186px;
                    }
                }

                width: 702px;
                height: 328px;
            }
        }
    }
}

.a4-three-plan {
    &-list {
        &-item {
            $item: &;
            position: relative;
            overflow: hidden;

            height: 261px;
            width: 284px;

            margin: 26px auto 0;
            @include paddingY(18px, 27px);

            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 30px;

            cursor: pointer;
            transition: background-color 0.2s ease-in-out;

            &-image {
                display: flex;
                justify-content: center;

                margin-bottom: 6px;

                img {
                    width: 153px;
                    height: 147px;
                }
            }

            &-title {
                font-size: 26px;
                line-height: 32px;
                font-weight: bold;
                text-align: center;
                color: var(--secondary);
                transition: color 0.2s ease-in-out;

                margin-bottom: 12px;
            }

            &-description {
                display: block;
                @include set-font(20px, 26px);
                color: rgba(var(--secondaryR), var(--secondaryG), var(--secondaryB), 0.6);
                text-align: center;
            }
        }
    }

    @include sm {
        &-list {
            display: flex;
            margin-left: -35px;

            &-item {
                $item: &;
                height: 305px;
                width: 219px;

                margin: unset;
                margin-left: 35px;
                &-description {
                    color: rgba(255, 255, 255, 0.6);
                }
                &:hover {
                    border-color: transparent;
                    background: var(--primary);

                    #{$item}-title {
                        color: white;
                    }

                    #{$item}-description {
                        display: block;
                    }
                }
            }
        }
    }

    @include md {
        &-list {
            margin-left: -35px;

            &-item {
                &-image {
                    img {
                        margin-top: 30px;
                        width: 153px;
                        height: 147px;
                    }
                }

                width: 304px;
                height: 328px
            }
        }
    }

    @include xl {
        &-list {
            margin-left: -25px;
            &-item {
                &-image {
                    img {
                        width: 195px;
                        height: 186px;
                    }
                }

                width: 456px;
                height: 328px;
            }
        }
    }
}

.a4-one-plan {
    &-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-wrap: wrap;
        &-item {
            $item: &;
            position: relative;
            overflow: hidden;

            height: 261px;
            width: 284px;

            margin: 26px auto 0;
            @include paddingY(18px, 27px);

            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 30px;

            cursor: pointer;
            -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
            transition: background-color 0.2s ease-in-out;

            &-image {
                display: flex;
                justify-content: center;

                margin-bottom: 6px;

                img {
                    width: 153px;
                    height: 147px;
                }
            }

            &-title {
                font-size: 26px;
                line-height: 24px;
                font-weight: bold;
                text-align: center;
                color: var(--secondary);
                transition: color 0.2s ease-in-out;

                margin-bottom: 12px;
            }

            &-description {
                display: block;
                @include set-font(20px, 26px);
                color: rgba(var(--secondaryR), var(--secondaryG), var(--secondaryB), 0.6);
                text-align: center;
            }
        }
    }

    @include sm {
        &-list {
            &-item {
                $item: &;

                margin: unset;
                margin-bottom: 36px;
                width: 347px;
                &-description {
                    color: rgba(255, 255, 255, 0.6);
                }
                &:hover {
                    border-color: transparent;
                    background: var(--primary);

                    #{$item}-title {
                        color: white;
                    }

                    #{$item}-description {
                        display: block;
                    }
                }
            }
        }
    }

    @include md {
        &-list {
            &-item {
                width: 473px;
            }
        }
    }

    @include xl {
        &-list {
            &-item {
                &-image {
                    img {
                        width: 195px;
                        height: 186px;
                    }
                }

                width: 702px;
                height: 328px;
            }
        }
    }
}