@import 'variables.scss';
@import 'functions.scss';
@import 'mixins.scss';
@import 'fonts.scss';
@import 'placeholders.scss';

.a4-terms {
    &-container {
        @include container($sm: 605px, $md: 814px, $xl: 1192px);
    }

    &-page-title {
        @extend %page-title;
    }

    &-pre {
        @extend %info-pre;
    }

    &-topics-summary {
        @extend %info-topics-summary;
    }

    &-topics {
        @extend %info-topics;
    }
}
