@import 'variables.scss';
@import 'functions.scss';
@import 'mixins.scss';
@import 'fonts.scss';
@import 'placeholders.scss';
@import 'button.scss';

.a4-course-details {
    $root: &;

    &-main {
        margin-top: 100px;

        &-container {
            padding-bottom: 74px;
            @include paddingX(20px);

            @include sm {
                padding-bottom: 60px;
            }

            @include md {
                padding-bottom: 140px;
            }

            @include xl {
                padding-bottom: 110px;
            }
        }

        &-content {
            position: relative;
        }

        &-cover {
            padding-top: 60px;
            display: flex;
            justify-content: center;

            img {
                width: 92px;
                height: 92px;
            }

            @include sm {
                padding-top: 0px;

                img {
                    width: 176px;
                    height: 176px;
                }
            }
        }

        &-title {
            margin-top: 50px;

            h1 {
                @extend .text-xl;
                text-align: center;

                @include sm {
                    width: 625px;
                    margin: auto;
                }
                @include md {
                    width: 702px;
                }
            }
        }

        &-back {
            position: absolute;
            top: 0;
            left: 0;

            a {
                display: flex;
                align-items: center;

                i {
                    display: flex;
                    margin-right: 30px;

                    svg {
                        width: 6px;
                        height: 12px;
                    }
                }

                span {
                    @include set-font(14px, 19px);
                    font-weight: normal;
                    color: var(--secondary);
                }

                @include md {
                    span {
                        @include set-font(16px, 22px);
                    }
                }
            }

            @include lg {
                left: 40px;
            }
        }

        @include sm {
            margin-top: 200px;
        }

        @include md {
            margin-top: 160px;
        }
    }

    &-info {
        &-container {
            @include paddingX(20px);

            @include sm {
                @include paddingX(0);

                max-width: 730px;
                margin: auto;
            }

            @include md {
                @include paddingX(0);

                max-width: 814px;
                margin: auto;
            }

            @include xl {
                @include paddingX(0);

                max-width: 1196px;
                margin: auto;
            }
        }

        &-content {
            @include md {
                display: flex;
                margin-bottom: 100px;
            }

            @include xl {
                margin-bottom: 140px;
            }
        }

        &-side {
            position: relative;
            @include paddingY(40px);
            @include paddingX(40px);
            &::after {
                content: "";
                @extend %a4-filler;
                background: #ffffff;
                border-radius: 30px;
            }
            @include shadow();
    
            #{$root}-list {
                &-item {
                    &:not(:first-child) {
                        margin-top: 35px;
                    }
    
                    &-label {
                        @include set-font(16px, 32px);
                        font-weight: normal;
                        color: var(--primary);
                    }
    
                    &-text {
                        @include set-font(18px, 1.0);
                        font-weight: bold;
                        color: var(--secondary);
                    }
    
                    @include sm {
                        width: 33%;
                        margin-top: 40px;
    
                        &:not(:first-child) {
                            margin-top: 40px;
                        }
    
                        &-label {
                            font-size: 13px !important;
                            line-height: 18px !important;
                        }
    
                        &-text {
                            @include set-font(14px, 28px);
                        }
                    }
                    @include md {
                        width: auto;
                        margin-top: 40px;
    
                        &-label {
                            @include set-font(14px, 21px);
                        }
    
                        &-text {
                            @include set-font(16px, 28px);
                            margin-top: 6px;
                        }
                    }
                }
    
                @include sm {
                    display: flex;
                    flex-wrap: wrap;
    
                    margin-top: -40px;
                }
    
                @include md {
                    display: block;
                }
            }
    
            @include sm {
                @include paddingY(50px);
                @include paddingX(70px);
            }
    
            @include md {
                width: 280px;
                min-width: 280px;
                margin-right: 60px;
    
                @include paddingY(30px, 40px);
                @include paddingX(40px);
    
                align-self: flex-start;
            }
    
            @include xl {
                margin-right: 160px;
            }
        }

        &-body {
            @include paddingY(80px);
    
            #{$root}-about {
                padding-bottom: 40px;
    
                &-title {
                    h2 {
                        @extend .text-lg;
                        font-weight: 800;
                    }
                }
    
                &-description {
                    @extend %description;
                    margin-top: 26px;

                    overflow: hidden;
                    max-height: 200px;

                    &.-blur {
                        &:after {
                            content  : "";
                            position : absolute;
                            z-index  : 1;
                            bottom   : 50px;
                            left     : 0;
                            pointer-events   : none;
                            background-image : linear-gradient(to bottom, 
                                              rgba(255,255,255, 0), 
                                              rgba(255,255,255, 1) 90%);
                            width    : 100%;
                            height   : 4em;
                        }
                    }

                    &.-show {
                        overflow: visible;
                        max-height: none;

                        &:after {
                            background-image:none;
                        }
                    }

                    p {
                        color: rgba(var(--secondaryR), var(--secondaryG), var(--secondaryB), 0.4);
                    }
                }
    
                &-control {
                    margin-top: 47px;
    
                    button {
                        background: none!important;
                        border: none;
                        padding: 0!important;
    
                        position: relative;
    
                        @include set-font(14px, 24px);
                        font-weight: bold;
                        color: var(--primary);
    
                        &:before {
                            content: "";
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            transform-origin: center;
                            width: 120%;
                            height: 200%;
                        }

                        &:hover {
                            text-decoration: underline;
                        }
    
                        @include md {
                            @include set-font(18px, 28px);
                        }
                    }
                }
            }
    
            #{$root}-from-author {
                $fromauthor: &;
    
                padding-top: 40px;

                @include md {
                    padding-top: 60px;
                }
    
                &-info {
                    display: flex;
                }
    
                &-avatar {
                    margin-right: 20px;
    
                    width: 60px;
                    height: 60px;
                    position: relative;
                    overflow: hidden;
                    border-radius: 50%;
    
                    flex-shrink: 0;
    
                    img {
                        display: inline;
                        margin: 0 auto;
                        height: 100%;
                        width: auto;
                    }
    
                    svg {
                        display: inline;
                        margin: 0 auto;
                        height: 100%;
                        width: auto;
    
                        color: var(--primary);
                    }
                }
    
                &-name {
                    @include set-font(14px, 24px);
                    font-weight: normal;
                    color: rgba(var(--secondaryR), var(--secondaryG), var(--secondaryB), 0.5);
    
                    @include sm {
                        @include set-font(16px, 24px);
                    }
                }
    
                &-from {
                    @include set-font(14px, 24px);
                    font-weight: bold;
                    color: var(--secondary);
    
                    @include sm {
                        @include set-font(16px, 24px);
                    }
                }
    
                &-comment {
                    @extend %description;
                    margin-top: 36px;

                    overflow: hidden;
                    max-height: 100px;

                    &.-blur {
                        &:after {
                            content  : "";
                            position : absolute;
                            z-index  : 1;
                            bottom   : 50px;
                            left     : 0;
                            pointer-events   : none;
                            background-image : linear-gradient(to bottom, 
                                              rgba(255,255,255, 0), 
                                              rgba(255,255,255, 1) 90%);
                            width    : 100%;
                            height   : 4em;
                        }
                    }

                    &.-show {
                        overflow: visible;
                        max-height: none;

                        &:after {
                            background-image: none;
                        }
                    }
    
                    p {
                        color: rgba(var(--secondaryR), var(--secondaryG), var(--secondaryB), 0.4);
                    }
                }
            }
    
            @include sm {
                @include paddingX(62px);
                @include paddingY(80px, 20px);
    
                &.-schedules {
                    @include paddingY(0px, 80px);
                }
            }
    
            @include md {
                @include paddingX(0);
                @include paddingY(0);
    
                &.-schedules {
                    margin-left: 35px;
                    @include paddingY(0px, 60px);
                }
            }
    
            @include lg {
                @include paddingX(0);
    
                &.-schedules {
                    @include paddingY(0px, 40px);
                }
            }
    
            @include xl {
                &.-schedules {
                    margin-left: 0px;
                    @include paddingY(0px, 20px);
                }
            }
        }
    }

    &-objectives {
        &-container {
            @include paddingX(20px);

            @include sm {
                @include paddingX(0);

                max-width: 730px;
                margin: auto;
            }

            @include md {
                @include paddingX(0);

                max-width: 814px;
                margin: auto;
            }

            @include xl {
                @include paddingX(0);

                max-width: 1196px;
                margin: auto;
            }
        }

        &-content {
            @include md {
                display: flex;
                margin-bottom: 140px;
            }
        }

        &-side {
            position: relative;
            @include paddingX(0px);

            @include sm {
                @include paddingY(80px, 0px);
                @include paddingX(62px);
            }

            @include md {
                width: 340px;
                min-width: 340px;

                @include paddingY(30px, 40px);
                @include paddingX(40px);

                align-self: flex-start;
            }

            @include xl {
                margin-right: 100px;
            }

            &::after {
                content: "";
                @extend %a4-filler;
                background: #ffffff;
                border-radius: 30px;
            }

            h3 {
                font-size: 24px;
                font-weight: 800;
                line-height: 32px;

                @include md {
                    font-size: 28px;
                    line-height: 42.5px;
                }
            }
        }

        &-body {
            position: relative;
            @include paddingY(0px, 80px);
    
            @include sm {
                @include paddingX(62px);
                @include paddingY(20px, 60px);
            }
    
            @include md {
                @include paddingX(0);
                @include paddingY(0);
            }
    
            @include lg {
                @include paddingX(0);
            }
        }

        &-sections {
            overflow: hidden;
            max-height: 300px;

            &.-blur {
                &:after {
                    content  : "";
                    position : absolute;
                    z-index  : 1;
                    bottom   : 50px;
                    left     : 0;
                    pointer-events   : none;
                    background-image : linear-gradient(to bottom, 
                                      rgba(255,255,255, 0), 
                                      rgba(255,255,255, 1) 90%);
                    width    : 100%;
                    height   : 4em;
                }
            }

            &.-show {
                overflow: visible;
                max-height: none;

                &:after {
                    background-image: none;
                }
            }
        }

        &-section {
            @include paddingY(60px, 0px);

            @include sm {
                @include paddingY(40px, 0px);

                &:not(:first-of-type) {
                    @include paddingY(60px, 0px);
                }
            }

            @include md {
                @include paddingY(30px, 0px);

                &:not(:first-of-type) {
                    @include paddingY(60px, 0px);
                }
            }

            &-header {
                @include set-font(24px, 34px);
                font-weight: 700;
            }
        }

        &-list {
            counter-reset: item;
    
            &-item {

                margin-top: 30px;
    
                &-number:before {
                    display: block;
                    margin-bottom: 30px;
        
                    min-width: 46px;
                    margin-right: 36px;
        
                    counter-increment: item;
                    content: counter(item, decimal-leading-zero) ".";
        
                    @include set-font(32px, 34px);
                    color: var(--secondary);
                    opacity: 0.20;
                    font-weight: normal;
                }
        
                &-title {
                    @extend .text-md;
                    font-weight: bold;
                }
        
                &-description {
                    @include set-font(16px, 28px);
                    color: var(--secondary);
                    opacity: 0.50;
                    margin-top: 10px;
        
                    @include md {
                        margin-top: 5px;
                    }

                    @include xl {
                        @include set-font(18px, 32px);
                    }
                }
        
                @include md {
                    display: flex;
        
                    &-number:before {
                        margin-bottom: 0px;
                        margin-top: 5px;
                    }
                }
                @include xl {
                    &-number:before {
                        @include set-font(42px, 34px);
                        min-width: 60px;
                        margin-top: 10px;
                    }
                }
            }
        }

        &-control {
            margin-top: 47px;

            button {
                background: none!important;
                border: none;
                padding: 0!important;

                position: relative;

                @include set-font(14px, 24px);
                font-weight: bold;
                color: var(--primary);

                &:before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    transform-origin: center;
                    width: 120%;
                    height: 200%;
                }
                &:hover {
                    text-decoration: underline;
                }

                @include md {
                    @include set-font(18px, 28px);
                }
            }
        }
    }

    &-schedules {
        &-container {
            @include sm {
                @include paddingX(20px);
                @include paddingX(0);

                max-width: 730px;
                margin: auto;
            }

            @include md {
                @include paddingX(0);

                max-width: 814px;
                margin: auto;
            }

            @include xl {
                @include paddingX(0);

                max-width: 1196px;
                margin: auto;
            }
        }

        &-content {
            @include md {
                display: flex;
                margin-bottom: 60px;
            }
            @include xl {
                margin-bottom: 140px;
            }
        }

        &-side {
            position: relative;
            @include paddingX(20px);

            @include sm {
                @include paddingX(40px);
                @include paddingY(40px);
            }

            &::after {
                content: "";
                @extend %a4-filler;
                background: #ffffff;
                border-radius: 30px;
            }

            h3 {
                font-size: 24px;
                font-weight: 800;
                line-height: 32px;

                @include md {
                    font-size: 28px;
                    line-height: 42.5px;
                }
            }

            @include sm {
                @include paddingY(50px);
                @include paddingX(62px);
            }

            @include md {
                width: 380px;
                min-width: 280px;

                @include paddingY(30px, 40px);
                @include paddingX(40px);

                align-self: flex-start;
            }

            @include xl {
                margin-right: 50px;
            }
        }

        &-body {
            @include paddingY(50px, 80px);
    
            @include sm {
                @include paddingX(62px);
                @include paddingY(0px, 80px);
            }
    
            @include md {
                @include paddingX(0);
                @include paddingY(0px, 60px);
                margin-left: 35px;
                
            }
    
            @include lg {
                @include paddingX(0);
                @include paddingY(0px, 40px);
            }
    
            @include xl {
                @include paddingY(0px, 20px);
                margin-left: 0px;
            }
        }
    }

    &-pricing {
        margin-bottom: 100px;

        &-course {
            width: 100%;
            height: 335px;
            background-color: var(--primary-light);
            background-image: url("./assets/mask.png");
            background-size: cover;
            background-repeat: no-repeat;

            @include sm {
                background-image: url("./assets/mask-sm.png")
            }
            @include md {
                background-image: url("./assets/mask-md.png")
            }
            @include lg {
                background-image: url("./assets/mask-lg.png")
            }
            @include xl {
                background-image: url("./assets/mask-xl.png")
            }

            .a4-course-plan {
                $root: &;
            
                &-container {
                    padding: 40px 0 35px 0;
                    margin-bottom: 26px;
                    min-height: 335px;
                    display: flex;
            
                    transition-duration: 0.4s;
                    transition-property: opacity height max-height;
            
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
            
                &-item {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    width: 100%;
            
                    transition: all 0.4s;
                }
            
                &-price {
                    color: var(--secondary);
                    font-weight: bolder;
            
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
            
                    &-digits {
                        display: flex;
                        align-items: flex-start;
                    }

                    line-height: 38px;
            
                    &-int {
                        font-size: 36px;
                    }
            
                    &-float {
                        font-size: 22px;
                        line-height: 28px;
                    }
            
                    margin-top: 20px;
                    margin-left: 30px;
                }

                &-title {
                    color: var(--secondary);
                    @include set-font(30px, 38px);
                    font-weight: bolder;

                    margin-top: 20px;
                    margin-left: 30px;
                }
            
                &-description {
                    $description: &;
                    @extend %description;
                    color: rgba(var(--secondaryR), var(--secondaryG), var(--secondaryB), 0.5);

                    @include set-font(18px, 34px);
                    font-weight: bold;
            
                    transition: max-height 0.25s;
                    transition-delay: 0s;
            
                    margin-top: 20px;
                    margin-left: 30px;

                    padding-right: 30px;

                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 3;
                    overflow: hidden;
            
                    @include sm {
                        max-height: 100%;
                    }
                }

                &-schedule {
                    $description: &;
                    @extend %description;
                    color: rgba(var(--secondaryR), var(--secondaryG), var(--secondaryB), 0.5);

                    @include set-font(16px, 24px);
                    font-weight: normal;
            
                    transition: max-height 0.25s;
                    transition-delay: 0s;
            
                    margin-top: 0px;
                    margin-left: 30px;

                    padding-right: 30px;
            
                    @include sm {
                        max-height: 100%;
                    }
                }

                &-section:last-of-type {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
            
                &-controls {
                    margin-top: 20px;
                    margin-right: 30px;
            
                    display: flex;
                    justify-content: space-between;
                }
            
                &-buy-now.a4-btn.-primary {
                    height: 55px;
                    width: 132px;
                    @include set-font(16px, 24px);
                }
                &-section-subsection button {
                    display: none;
                }

                @include sm {
                    &-container {
                        width: 731px;
                        min-height: 0;
                        margin: 0 auto;
                        padding: 60px 50px 50px 50px;
                    }
            
                    &-item {
                        position: relative;
                        min-height: 0;
                    }
            
                    &-title {
                        color: var(--secondary);
                        @include set-font(48px, 72px);
                        padding-right: 120px;
                        margin-left: 10px;
                    }
            
                    &-description {
                        @include set-font(20px, 34px);
                        padding-right: 275px;
                        visibility: visible;
                        max-height: 1000px;
                        margin-top: 20px;
                        margin-left: 10px;
                    }

                    &-schedule {
                        @include set-font(18px, 24px);
                        padding-right: 275px;
                        visibility: visible;
                        max-height: 1000px;
                        margin-left: 10px;
                    }
            
                    &-price {
                        position: absolute;
                        top: 0;
                        right: 0;
            
                        margin-top: 30px;
                        margin-right: 20px;

                        line-height: 64px;

                        &-int {
                            font-size: 48px;
                        }
                    }
                    
                    &-section-subsection {
                        margin-top: -70px;
                        display: flex;
                        flex-direction: row-reverse;
                        button {
                            display: flex;
                            margin-top: 20px;
                        }
                    }
                    
                    &-controls {
                        margin-top: 15px;
                        justify-content: flex-start;
                        display: none;
                    }
            
                    &-buy-now.a4-btn.-primary.-bubble {
                        width: 209px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        @include set-font(14px, 20px);
                    }
                }
            
                @include md {
                    &-container {
                        border-radius: 30px;
                        width: 890px;
                        margin-bottom: 36px;
                    }
            
                    &-item {
                        display: flex;
                        flex-wrap: nowrap;
                        flex-direction: row;
                    }
            
                    &-section:not(:first-of-type) {
                        margin-left: 30px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                    }

                    &-title {
                        display: flex;
                        @include set-font(52px, 72px);
                        align-items: center;
                        padding-right: 0;
                        margin-left: 0px;
                    }
            
                    &-description {
                        padding-right: 70px;
                        margin-top: 40px;
                        margin-left: 0px;
                    }

                    &-schedule {
                        padding-right: 70px;
                        margin-left: 0px;
                    }
            
                    &-section {    
                        &:first-of-type {
                            flex: 1;
                        }
                    }
                    &-section:last-of-type {
                        justify-content: flex-end;
                    }
            
                    &-price {
                        margin-right: 0px;
                        margin-left: 30px;
            
                        &-int {
                            font-size: 52px;
                        }
            
                        &-float {
                            @include set-font(28px, 40px);
                        }
                    }
                    &-buy-now.a4-btn.-primary {
                        @include set-font(16px, 42px, 0.01em);
                    }
            
                    &-controls {
                        margin-top: 0;
                        width: auto;
                        flex-direction: column;
                        align-items: center;
                        display: flex;
                    }
                    &-section:last-of-type {
                        width: 120px;
                        min-height: 120px;
                    }
                    &-section-subsection {
                        flex-direction: row;
                        margin-top: 20px;
                        .a4-course-plan-buy-now.a4-btn.-primary.-bubble {
                            display: none;
                        }
                    }
                }
            
                @include lg {
                    &-price {
                        margin-right: 10px;
                    }  
                    &-controls.-bottomMargin {
                        margin-bottom: 15px;
                    }  
                    &-buy-now.a4-btn.-primary {
                        @include set-font(18px, 42px);
                    }    
                }
            
                @include xl {
                    &-container {
                        width: 1194px;
                        padding: 90px 70px;
                    }
            
                    &-item {
                        display: flex;
                        flex-wrap: nowrap;
                        flex-direction: row;
                    }
                    &-title {
                        margin-top: 0px;
                        @include set-font(64px, 64px);
                    }
                    &-description {
                        @include set-font(24px, 34px);
                        margin-top: 50px;
                        padding-right: 0px;
                    }
                    &-schedule {
                        @include set-font(20px, 24px);
                        margin-top: 10px;
                        padding-right: 0px;
                    }
                    &-section:not(:first-of-type) {
                        margin-left: 135px;
                    }
                    &-price {
                        margin-top: 0px;
                        margin-right: 0px;
                        
                        font-feature-settings: "pnum" on, "lnum" on;
                        &-int {
                            @include set-font(64px, 64px);
                        }
            
                        &-float {
                            @include set-font(36px, 42px);
                        }
                    }
                    &-controls.-bottomMargin {
                        margin-bottom: 25px;
                    }
                    &-buy-now.a4-btn.-primary.-bubble {
                        @include set-font(18px, 20px);
                    }
                }
            }
        }

        &-container {
            @include paddingX(20px);

            @include sm {
                @include paddingX(0);

                max-width: 730px;
                margin: auto;

                &:nth-child(2) {
                    padding-top: 50px;
                }
            }

            @include md {
                @include paddingX(0);

                max-width: 914px;
                margin: auto;
            }

            @include xl {
                @include paddingX(0);

                max-width: 1196px;
                margin: auto;
            }
        }

        &-title {
            color: var(--secondary);
            @include set-font(30px, 38px);
            font-weight: bolder;
            margin-top: 80px;

            @include sm {
                @include set-font(36px, 42px);
                margin-left: 60px;
            }

            @include xl {
                @include set-font(40px, 46px);
                margin-left: 70px;
                font-weight: 700;
            }
        }

        .a4-pricing-plan {
            $root: &;
        
            &-container {
                padding: 40px 0 35px 0;
                margin-bottom: 26px;
                min-height: 300px;
                display: flex;
        
                &.-course-bundle {
                    min-height: 426px;
                }
        
                transition-duration: 0.4s;
                transition-property: opacity height max-height;
        
                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        
            &-item {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 100%;
        
                transition: all 0.4s;
            }
        
            &-price {
                color: var(--secondary);
                font-weight: bold;
        
                display: flex;
                flex-direction: column;
                align-items: flex-start;
        
                &-digits {
                    display: flex;
                    align-items: flex-start;
                }
                line-height: 38px;
        
                &-int {
                    font-size: 36px;
                }
        
                &-float {
                    font-size: 22px;
                    line-height: 28px;
                }
        
                margin-top: 20px;
                margin-left: 10px;
            }

            &-title {
                color: var(--secondary);
                @include set-font(20px, 28px);
                font-weight: bold;
            }
        
            &-description {
                $description: &;
                @extend %description;
                color: rgba(var(--secondaryR), var(--secondaryG), var(--secondaryB), 0.5);
        
                transition: max-height 0.25s;
                transition-delay: 0s;
        
                margin-top: 20px;
        
                @include sm {
                    max-height: 100%;
                }
            }
        
            &-advantages {
                display: flex;
                flex-direction: column;
                margin-top: 20px;
                opacity: 0;

                &.-course-bundle {
                    svg {
                        opacity: 1;
                        width: 10px;
                        height: 8px;
                        margin-right: 10px;
                    }
        
                    display: block;
                    max-height: 1000px;
                    opacity: 1;
                }
        
                &-item {
                    &-text {
                        color: var(--secondary);
                        @include set-font(13px, 24px);
                        font-weight: 600;

                        &.-primary {
                            color: var(--primary);
                        }
                    }
                    &-icon {
                        display: flex;
                        margin-right: 12px;
        
                        img {
                            @include size(15px);
                            margin-right: 10px;
                        }
                    }
                    &.-course-bundle {
                        max-height: 1000;
                        display: flex;
                        align-items: baseline;
                    }
                }
            }
        
            &-controls {
                margin-top: 20px;
                margin-right: 10px;
        
                display: flex;
                justify-content: space-between;
            }
        
            &-buy-now.a4-btn.-primary {
                height: 55px;
                @include set-font(16px, 24px);
            }

            &-buy-now.a4-btn.-primary.-inverted {
                @include paddingY(10px);
                @include paddingX(30px);

                border: 1px solid var(--primary);
                border-radius: 30px;
                color: var(--primary);

                @include fill(#fff);
                @include sm {
                    @include paddingY(14px);
                }
            }
        
            &-section:last-of-type {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 20px;
            }
            &-section-subsection button {
                display: none;
            }

            @include sm {
                &-container {
                    width: 731px;
                    min-height: 0;
                    margin: 0 auto;
                    padding: 60px 60px 60px 60px;
                }
        
                &-item {
                    position: relative;
                    min-height: 0;
                }
        
                &-title {
                    @include set-font(22px, 34px);
                    padding-right: 120px;
                }
        
                &-description {
                    padding-right: 180px;
                    margin-top: 40px;
                }
        
                &-description.-course-bundle {
                    padding-right: 0px;
                }
        
                &-price {
                    position: absolute;
                    top: 0;
                    right: 0;
        
                    margin-top: 0;
                    margin-right: 20px;
                }
                
                &-section-subsection {
                    margin-top: -70px;
                    display: flex;
                    flex-direction: row-reverse;
                    button {
                        display: flex;
                        margin-top: 20px;
                    }
                }
                &-advantages {
                    display: flex;
                    flex-wrap: wrap;
                    opacity: 1;
        
                    width: 100%;
                    min-height: 55px;
                    max-height: 1000px;
        
                    &.-course-bundle {
                        display: flex;
                        flex-direction: row;
                        margin-top: 40px;
                    }
        
                    &-item {
                        position: relative;
                        visibility: visible;
        
                        display: flex;
                        align-items: baseline;
                        width: 50%;
                        padding-right: 20px;
                        svg {
                            color: var(--secondary);
                            margin-right: 10px;
                        }
        
                        &-title {
                            background-color: transparent;
                            @include set-font(13px, 24px);
                            margin-bottom: 6px;
                        }
                        &-value {
                            @include set-font(14px, 26px);
                            font-weight: bold;
                        }
                        &.-course-bundle {
                            max-height: 1000px;
                        }
                    }
                }
                &-controls {
                    margin-top: 15px;
                    justify-content: flex-start;
                    display: none;
                }
        
                &-buy-now.a4-btn.-primary.-bubble {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    @include set-font(14px, 20px);
                }
            }
        
            @include md {
                &-container {
                    border-radius: 30px;
                    width: 914px;
                    margin-bottom: 36px;
                }
        
                &-item {
                    display: flex;
                    flex-wrap: nowrap;
                    flex-direction: row;
                }
        
                &-section:not(:first-of-type) {
                    margin-left: 30px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                }
                &-title {
                    display: flex;
                    @include set-font(28px, 42px);
                    align-items: center;
                    padding-right: 0;
                }
        
                &-description {
                    padding-right: 70px;
                    margin-top: 50px;
                }
        
                &-section {
                    &:first-of-type {
                        flex: 1;
                    }
                }
                &-section:last-of-type {
                    justify-content: flex-end;

                    &.-course-bundle {
                        justify-content: flex-start;
                    }
                }
        
                &-price {
                    margin-right: 0px;
                    margin-left: 30px;
        
                    &-int {
                        @include set-font(48px, 54px);
                    }
        
                    &-float {
                        @include set-font(28px, 40px);
                    }
                }
                &-buy-now.a4-btn.-primary {
                    @include set-font(16px, 42px, 0.01em);
                    width: fit-content;
                }
                &-advantages {
                    width: auto;
                    flex: 1;
                    margin-top: 40px;
        
                    &-item {
                        &-text {
                            @include set-font(14px, 26px);
                        }
                    }
                }
        
                &-controls {
                    margin-top: 0;
                    width: auto;
                    flex-direction: column;
                    align-items: center;
                    display: flex;

                    &.-course-bundle {
                        margin-top: 80px;
                    }
                }
                &-section:last-of-type {
                    width: 120px;
                    min-height: 120px;
                }
                &-section-subsection {
                    flex-direction: row;
                    margin-top: 20px;
                    .a4-pricing-plan-buy-now.a4-btn.-primary.-bubble {
                        display: none;
                    }
                }
            }
        
            @include lg {
                &-price {
                    margin-right: 10px;
                }        
            }
        
            @include xl {
                &-container {
                    width: 1294px;
                    padding-top: 90px;
                    padding-right: 170px;
                    padding-left: 70px;
                    padding-bottom: 90px;
                }
        
                &-item {
                    display: flex;
                    flex-wrap: nowrap;
                    flex-direction: row;
                }
                &-title {
                    @include set-font(32px, 32px);
                }
                &-description {
                    margin-top: 70px;
                }
                &-advantages {
                    margin-top: 40px;
                    &-item {
                        &-text {
                            @include set-font(16px, 28px);
                        }
                    }
                }
                &-controls {
                    &.-course-bundle {
                        margin-top: 100px;
                    }
                }
                &-section:not(:first-of-type) {
                    margin-left: 135px;
                }
                &-price {
                    margin-right: 0px;
                    
                    font-feature-settings: "pnum" on, "lnum" on;
                    &-int {
                        @include set-font(64px, 64px);
                    }
        
                    &-float {
                        @include set-font(36px, 42px);
                    }
                }
                &-buy-now.a4-btn.-primary.-bubble {
                    @include set-font(18px, 20px);
                }
            }
        }
    }
}
