@import 'variables.scss';
@import 'functions.scss';
@import 'mixins.scss';
@import 'fonts.scss';
@import 'placeholders.scss';
@import 'button.scss';

.a4-empty-exposition {
    $root: &;

    &-container {
        @include container($sm: 728px, $md: 984px, $xl: 1440px);
        @include paddingX(18px);
    }

    &-content {
        @include marginY(20px);
        display: flex;
        align-items: center;
        flex-direction: column;

        &.-shell {
            border-radius: 30px;
            background: var(--primary-light);
        }

        @include sm {
            @include marginY(20px, 20px);

            flex-direction: row;
            justify-content: space-between;

            &.-shell {
                border-radius: 40px;
            }
        }
        @include md {
            @include marginY(40px, 40px);
        }
        @include lg {
            @include marginY(40px, 40px);
        }
        @include xl {
            @include marginY(60px, 60px);
        }

        &.-courses {
            @include sm {
                padding-left: 20px;
                padding-right: 60px;
            }
            @include md {
                padding-left: 0px;
                padding-right: 150px;
            }
            @include xl {
                padding-left: 20px;
                padding-right: 250px;
            }
        }

        &.-pricing {
            @include sm {
                padding-left: 48px;
            }
            @include md {
                padding-left: 78px;
                padding-right: 32px;
            }
            @include xl {
                padding-left: 170px;
                padding-right: 120px;
            }
        }
    }

    &-illustration {
        text-align: center;

        img {
            display: block;
            width: 327px;
            height: 277px;

            @include sm {
                width: 385px;
                height: 326px;
            }
            @include md {
                width: 456px;
                height: 386px;
            }
            @include lg {
                width: 472px;
                height: 400px;
            }
            @include xl {
                width: 568px;
                height: 481px;
            }
        }
    }

    &-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        margin-bottom: 40px;
        z-index: 1;

        @include sm {
            display: block;
            text-align: left;

            max-width: 284px;
            margin-bottom: 0;
        }
        @include md {
            max-width: 373px;
        }
        @include xl {
            max-width: 460px;
        }
    }

    &-title {
        @extend .text-xl;
        font-weight: bold;
    }

    &-control {
        margin-top: 40px;

        @include sm {
            margin-top: 50px;
        }
        @include xl {
            margin-top: 60px;
        }
    }

    &-description {
        @extend %description;
    }
}