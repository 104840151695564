%info-pre {
    margin-top: 60px;
    @include set-font(14px, 26px);
    color: rgba(var(--secondaryR), var(--secondaryG), var(--secondaryB), 0.5);
    .title {
        @extend .text-sm;
        font-weight: bold;
    }
    .column {
        margin-top: 20px;
    }
    @include sm {
        .cols {
            display: flex;
            justify-content: space-between;
        }
        .column {
            width: 282px;
        }
    }
    @include md {
        .column {
            width: 364px;
            margin-top: 30px;
            @include set-font(16px, 28px, 0.01em);
        }
    }
    @include xl {
        .column {
            width: 533px;
            margin-top: 44px;
            @include set-font(18px, 32px, 0.02em);
        }
    }
}

%page-title {
    padding-top: 130px;

    .title {
        @extend .text-xl;
        color: var(--secondary);
    }

    .description {
        margin-top: 10px;

        @extend .text-sm;
        color: var(--secondary);
    }

    @include sm {
        padding-top: 180px;
    }

    @include xl {
        padding-top: 200px;
    }
}

%info-topics-summary {
    @include marginY(80px);
    position: relative;

    .title {
        @extend .text-lg;
        text-align: center;
    }

    .summary-items {
        margin-top: 44px;
        @include paddingY(50px);
        position: relative;
        counter-reset: items-counter;

        &-fill {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            background: var(--primary-light);

            max-width: 100vw;
            margin: 0 calc(-50vw + 50%);
            z-index: -1;

            @include sm {
                margin: auto;
                max-width: unset;
                left: -64px;
                right: -64px;
                border-radius: 30px;
            }

            @include md {
                left: -85px;
                right: -85px;
            }

            @include xl {
                left: -123px;
                right: -123px;
            }
            
            &-button {
                color: var(--primary);
                background: none!important;
                border: none;
                padding: 0!important;
                cursor: pointer;
                font-weight: 700;

                text-align: left;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        ul {
            flex-flow: column wrap;
            margin-top: -24px;    

            li {
                margin-top: 24px;
            }

            li {
                @extend .text-sm;
                font-weight: bold;
                color: var(--primary);

                display: flex; // Erick


                a:hover {
                    text-decoration: underline;
                }

                &:before {
                    content: counter(items-counter) ". ";
                    counter-increment: items-counter;

                    padding-right: 15px; // Erick
                }
            }

            &:nth-of-type(2) {
                display: none;
            }

            @include sm {
                &:nth-of-type(2) {
                    display: flex;
                }
            }
        }

        &-control {
            position: relative;
            display: block;
            width: 20px;
            margin: 30px auto 0;

            cursor: pointer;

            &:before {
                content: "";
                position: absolute;
                top: 50%;
                right: 0;
                left: 50%;
                bottom: 0;
                transform: translate(-50%, -50%);
                transform-origin: center;

                width: 200%;
                height: 200%;
            }

            svg {
                width: 100%;
                height: 20px;
            }

            @include sm {
                display: none;
            }
        }

        @include sm {
            margin-top: 64px;
            @include paddingY(60px);
            display: flex;

            ul {
                flex: 1;
            }

            ul + ul {
                margin-left: 38px;
            }
        }

        @include md {
            ul + ul {
                margin-left: 86px;
            }
        }

        @include xl {
            margin-top: 64px;
            @include paddingY(90px);
            ul + ul {
                margin-left: 203px;
            }
        }
    }

    @include sm {
        @include marginY(80px, 100px);
    }

    @include md {
        @include marginY(100px);
    }
}

%info-topics {
    margin-bottom: 60px;
    counter-reset: topic-counter;

    .topic + .topic {
        margin-top: 80px;
    }

    .topic {
        &-title {
            @extend .text-lg;
            font-weight: 800;
            color: var(--secondary);

            padding-left: 50px;
            position: relative;

            &:before {
                position: absolute;
                top: 0;
                left: 0;
                content: counter(topic-counter) ".";
                counter-increment: topic-counter;

                @extend .text-lg;
                color: var(--primary);
                // color: rgba(var(--secondaryR), var(--secondaryG), var(--secondaryB), 0.2);
            }
        }

        .cols {
            margin-top: 34px;
            @extend .text-sm;

            @include xl {
                margin-top: 40px;
            }
        }

        .column {
            .title {
                @include set-font(18px, 28px, 0.02em);
                margin-bottom: 22px;
            }
            .description {
                margin-bottom: 40px;
                display: inline-block;
                &:last-of-type {
                    margin-bottom: 0;
                }
            }
            &:first-of-type {
                margin-bottom: 40px;
            }
        }

        @include md {
            & + & {
                margin-top: 100px;
            }
        }

        // Erick
        ul {
            margin-left: 45px;
            list-style: circle;
        }
    }

    @include sm {
        margin-bottom: 100px;
        .cols {
            display: flex;
            justify-content: space-between;
            .column {
                width: 276px;
                &:first-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }
    @include md {
        .cols {
            .column {
                width: 356px;
                @include set-font(16px, 28px, 0.01em);
                .title {
                    @include set-font(20px, 30px);
                }
            }
        }
    }
    @include xl {
        .cols {
            .column {
                width: 534px;
                @include set-font(18px, 32px, 0.02em);
                .title {
                    @include set-font(24px, 32px);
                }
            }
        }
    }
}

%description {
    margin-top: 20px;

    @extend .text-sm;
    font-weight: normal;
    color: var(--secondary);

    @include xl {
        margin-top: 30px;
    }

    &.-courses {
        @include xl {
            margin-top: 25px;
        }
    }
}

%a4-filler {
    position: absolute;
    @include inset(0px);
    z-index: -1;
}

%a4-mobile-filter {
    display: none;

    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 1000;
    overflow: hidden;

    background: #fff;
    .a4-mobile-filter {
        &-container {
            padding: 0 20px;
            height: 100%;
            overflow-y: auto;
        }

        &-content {
            padding-top: 26px;
            @include paddingY(26px, 60px);
        }

        &-top {
            display: flex;
            justify-content: space-between;
            position: relative;

            padding-bottom: 30px; // no padding-bottom
        }

        &-title {
            h4 {
                @include set-font(18px, 28px);
                font-weight: bold;
                color: var(--secondary);
            }
        }

        &-cancel {
            position: absolute;
            right: 5px;

            &:before {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                transform-origin: center;
                width: 200%;
                height: 200%;
                cursor: pointer;
            }

            svg {
                width: 30px;
                height: 26px;
            }
        }

        &-search {
            @include marginY(0px, 30px); // @include marginY(46px, 30px);
        }

        &-list {
            &-title {
                h4 {
                    @include set-font(14px, 25px);
                    font-weight: bold;
                    color: var(--secondary);

                    &:after {
                        content: ":";
                        display: inline;
                    }
                }
            }

            & + & {
                margin-top: 23px;
            }
        }

        &-items {
            padding: 10px 0;
            margin: 0 -20px;
            width: 100vw;

            &-item {
                position: relative;
                @include set-font(14px, 25px);

                @include paddingY(17px);
                padding-left: 33px;

                cursor: pointer;

                &.-active {
                    color: var(--primary);
                    background: var(--primary-light);
                    font-weight: 600;

                    &:after {
                        content: "";
                        position: absolute;
                        right: 40px;
                        top: 50%;
                        transform: translateY(-50%);
                        background: url("../assets/check.svg");
                        background-repeat: no-repeat;
                        width: 15px;
                        height: 10px;
                    }
                }
            }
        }

        &-control {
            position: fixed;
            z-index: 1001;
            bottom: 10px;
            left: 50%;
            transform: translateX(-50%);
            width: 284px;

            button {
                width: 100%;
            }
        }
    }

    &.-open {
        display: block;
    }
}
