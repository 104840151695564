@import 'variables.scss';
@import 'functions.scss';
@import 'mixins.scss';
@import 'fonts.scss';
@import 'placeholders.scss';

.a4-affiliated-sites {
    &-container {
      @include container(
        $sm: 605px,
        $md: 814px,
        $xl: 1192px
      );
    }
  
    &-page-title{
      @extend %page-title;
    }
  
    &-list {
      margin-bottom: 80px;
      padding-top: 80px;
  
      &-items {
        $margin-top: 60px;
        margin-top: -$margin-top;
  
        &-item {
          margin-top: $margin-top;
  
          &-text {
            @extend .text-sm;
            color: rgba(var(--secondaryR), var(--secondaryG), var(--secondaryB), 0.5);
          }
  
          &-logo {
            margin-bottom: 30px;
  
            img {
              width: auto;
              max-width: 250px;

              @include xl {
                max-width: 334px;
              }
            }
  
            @include sm {
              margin-bottom: 40px;
            }
  
            @include md {
              margin-bottom: 60px;
            }
          }
        }
  
        @include sm {
          $margin-left: 36px;
          $margin-top: 90px;
  
          display: flex;
          flex-wrap: wrap;
          margin-left: -$margin-left;
          margin-top: -$margin-top;
  
          &-item {
            margin-left: $margin-left;
            width: 284px;
          }
        }
  
        @include md {
          $margin-left: 86px;
          $margin-top: 86px;
          margin-left: -$margin-left;
          margin-top: -$margin-top;
  
          &-item {
            width: 363px;
            margin-left: $margin-left;
            margin-top: $margin-top;
          }
        }
  
        @include xl {
          $margin-left: 124px;
          $margin-top: 118px;
          margin-left: -$margin-left;
          margin-top: -$margin-top;
  
          &-item {
            width: 533px;
            margin-left: $margin-left;
            margin-top: $margin-top;
          }
        }
      }
  
      @include sm {
        margin-bottom: 140px;
        padding-top: 100px;
      }
      @include md {
        margin-bottom: 200px;
      }
      @include xl {
        padding-top: 110px;
      }
    }
  }