
.a4-input-group {
    display: flex;
    justify-content: space-between;

    .a4-input.firstName {
        display: none;
    }

    .a4-input.middleName {
        display: none;
        margin-left: 10px;
        margin-right: 10px;
    }

    .a4-input.lastName {
        display: none;
    }

    .a4-input:last-of-type {
        margin-bottom: 34px;
    }
}

.a4-create-account-form {
    .a4-input:last-of-type {
        margin-bottom: 0px;
    }
}